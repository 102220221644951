import cn from 'classnames'

import styles from './styles.module.scss'

export default function Skeleton({ className }: { className?: string }) {
  return (
    <div className={cn(styles.skeleton, className)}>
      <div></div>
    </div>
  )
}
