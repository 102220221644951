import { useEffect, useState } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import LogoInverted from 'components/ui/logotype/logotype-inverted.svg'

import { useRouter } from 'next/router'

import LogoDark from './icons/logo_dark.svg'

import IconTwitter from './icons/twitter.svg'
import IconTelegram from './icons/telegram.svg'
import IconMail from './icons/mail.svg'

import styles from './styles.module.scss'

export default function Footer() {
  const [windowWidth, setWindowWidth] = useState(0)
  const [isDark, setIsDark] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', (event) => {
      setWindowWidth(event.currentTarget.innerWidth)
    })
  }, [])

  useEffect(() => {
    if (router) {
      setIsDark(router.pathname.includes('private-clients'))
    }
  }, [router])

  return (
    <div className={cn(styles.footer, { [styles.footer_vip]: isDark })}>
      <div className={cn('container', styles.footerContainer)}>
        <div className={styles.legalInfo}>
          <div className={styles.copyright}>
            <Link prefetch={false} href="/">
              <a>
                <img src={isDark ? LogoDark : LogoInverted} alt="CoinRabbit" width={123} height={25} loading="lazy" />
              </a>
            </Link>
            <p className={styles.copyrightText}>{new Date().getFullYear()} © Coinrabbit</p>
          </div>
          <div className={styles.legalEntity}>
            Rabbit Finance LLC, <span> </span>
            <br className={styles.brToHide} />
            Richmond Hill Rd, Kingstown,
            <br />
            St. Vincent and the Grenadines, <span> </span>
            <br className={styles.brToHide} />
            VC0100
            <br />
          </div>
        </div>
        <div className={styles.rightBlock}>
          {(windowWidth < 655 && (
            <div className={styles.column}>
              <Link prefetch={false} href="/assets" passHref>
                <a>
                  <b>Crypto assets</b>
                </a>
              </Link>

              <div className={styles.innerColumn}>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/btc">
                    <a>Bitcoin (BTC) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/eth">
                    <a>Ethereum (ETH) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bch">
                    <a>Bitcoin Cash (BCH) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xno">
                    <a>Nano (XNO) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/doge">
                    <a>DogeCoin (DOGE) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xrp">
                    <a>Ripple (XRP) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/firo">
                    <a>Firo (FIRO) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xmr">
                    <a>Monero (XMR) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/rvn">
                    <a>Ravencoin (RVN) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/shib">
                    <a>Shiba Inu (SHIB) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dgb">
                    <a>DigiByte (DGB) loan</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/zil">
                    <a>Zilliqa (ZIL) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ada">
                    <a>Cardano (ADA) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/lrc">
                    <a>Loopring (LRC) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/uni">
                    <a>Uniswap (UNI) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/link">
                    <a>ChainLink (LINK) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/vet">
                    <a>VeChain (VET) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/sushi">
                    <a>Sushi Token (SUSHI) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ilv">
                    <a>Illuvium (ILV) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zrx">
                    <a>0x (ZRX) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/sol">
                    <a>Solana (SOL) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dash">
                    <a>Dash (DASH) loan</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/mana">
                    <a>Decentraland (MANA) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/trx">
                    <a>Tron (TRX) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bone">
                    <a>Bone (BONE) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/volt">
                    <a>Volt Inu (VOLT) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/aave">
                    <a>Aave (AAVE) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ftm">
                    <a>Fantom (FTM) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bnb">
                    <a>Binance (BNB) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dot">
                    <a>Polkadot (DOT) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ltc">
                    <a>Litecoin (LTC) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/mkr">
                    <a>Maker(MKR) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/matic">
                    <a>Polygon (Matic) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zec">
                    <a>Zcash (ZEC) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/algo">
                    <a>Algorand (ALGO) loan</a>
                  </Link>
                </div>
              </div>
            </div>
          )) || (
            <div className={styles.column}>
              <Link prefetch={false} href="/assets" passHref>
                <a>
                  <b>Crypto assets</b>
                </a>
              </Link>
              <div className={styles.innerColumn}>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/btc">
                    <a>Bitcoin (BTC) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/eth">
                    <a>Ethereum (ETH) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bch">
                    <a>Bitcoin Cash (BCH) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xno">
                    <a>Nano (XNO) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/doge">
                    <a>DogeCoin (DOGE) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xrp">
                    <a>Ripple (XRP) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/firo">
                    <a>Firo (FIRO) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xmr">
                    <a>Monero (XMR) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/rvn">
                    <a>Ravencoin (RVN) loan</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/shib">
                    <a>Shiba Inu (SHIB) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dgb">
                    <a>DigiByte (DGB) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zil">
                    <a>Zilliqa (ZIL) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ada">
                    <a>Cardano (ADA) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/lrc">
                    <a>Loopring (LRC) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/uni">
                    <a>Uniswap (UNI) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/link">
                    <a>ChainLink (LINK) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/vet">
                    <a>VeChain (VET) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/jasmy">
                    <a>JasmyCoin (JASMY) loan</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/ilv">
                    <a>Illuvium (ILV) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zrx">
                    <a>0x (ZRX) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/sol">
                    <a>Solana (SOL) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dash">
                    <a>Dash (DASH) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/mana">
                    <a>Decentraland (MANA) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/trx">
                    <a>Tron (TRX) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bone">
                    <a>Bone (BONE) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/volt">
                    <a>Volt Inu (VOLT) loan</a>
                  </Link>
                  <Link prefetch={false} href="/loans/aave">
                    <a>Aave (AAVE) loan</a>
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className={cn(styles.column, styles.loans)}>
            <Link prefetch={false} href="/" passHref>
              <a>
                <b>Crypto loans</b>
              </a>
            </Link>
            <Link prefetch={false} href="/loans/usdt">
              <a>Tether (USDT ERC-20)</a>
            </Link>
            <Link prefetch={false} href="/loans/usdttrc20">
              <a>Tether (USDT TRC-20)</a>
            </Link>

            <Link prefetch={false} href="/wallet" passHref>
              <a>
                <b className={styles.marginLink}>Crypto wallet</b>
              </a>
            </Link>
            <Link prefetch={false} href="/wallet-coin/btc">
              <a>Bitcoin (BTC) wallet</a>
            </Link>

            <Link prefetch={false} href="/exchange" passHref>
              <a>
                <b className={styles.marginLink}>Crypto exchange</b>
              </a>
            </Link>
            <Link prefetch={false} href="/exchange-pair">
              <a>BTC to XMR</a>
            </Link>
          </div>
          <div className={cn(styles.column, styles.earnings)}>
            <Link prefetch={false} href="/earn" passHref>
              <a>
                <b>Earn interest on crypto</b>
              </a>
            </Link>
            <Link prefetch={false} href="/earn">
              <a>Tether (USDT ERC-20)</a>
            </Link>
            <Link prefetch={false} href="/earn">
              <a>Tether (USDT TRC-20)</a>
            </Link>
            <Link prefetch={false} href="/earn">
              <a>USD Coin (USDC)</a>
            </Link>
          </div>
          <div className={styles.socials}>
            <div className={styles.socialsBox}>
              <a href="mailto:support@coinrabbit.io" title="contact us" aria-label="go-to-mail">
                <img
                  className={styles.iconMail}
                  src={IconMail}
                  alt="Contact Us: support@coinrabbit.io"
                  width={16}
                  height={10}
                  loading="lazy"
                />
              </a>
              <a
                href="https://twitter.com/CoinRabbitLoans"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="twitter"
                aria-label="go-to-twitter-social"
              >
                <img src={IconTwitter} alt="CoinRabbit in twitter" width={16} height={14} loading="lazy" />
              </a>
              <a
                href="https://t.me/+vKoWSNIXLbZmZTQy"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="telegram"
                aria-label="go-to-telegram-social"
              >
                <img src={IconTelegram} alt="CoinRabbit in telegram" width={18} height={14} loading="lazy" />
              </a>
            </div>
            <div className={styles.privacyPolicy}>
              <Link prefetch={false} href="/partners">
                <a>For partners</a>
              </Link>
              <Link prefetch={false} href="/terms-of-use">
                <a>Terms of use</a>
              </Link>
              <Link prefetch={false} href="/privacy-policy">
                <a>Privacy Policy</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={cn('container', styles.footerContainer)}>
        <p className={styles.footerText}>
          To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable
          for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, loss of data or other information), even if the Company or any supplier has been
          advised of the possibility of such damages and even if the remedy fails of its essential purpose.
        </p>
      </div>
    </div>
  )
}
