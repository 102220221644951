export enum EMethods {
  post = 'POST',
  get = 'GET',
  put = 'PUT',
}

export interface IOptions {
  headers?: {
    [key: string]: string
  }
  timeout?: number
}

export interface IConfig extends IOptions {
  baseURL?: string
}

export interface IDictionary<T> {
  [key: string]: T
}

export type TMethodToParams = {
  [EMethods.post]: any
  [EMethods.put]: any
  [EMethods.get]: IDictionary<string | number>
}

export type TParams<M extends EMethods> = IOptions & { params?: TMethodToParams[M] }

export interface IRequestResponse<TResponseData> {
  data: TResponseData
  status: number
}

export class RequestError<TResponseData = unknown> extends Error {
  public status: number
  public response: IRequestResponse<TResponseData>

  constructor(message: string, status: number, response: IRequestResponse<TResponseData>) {
    super(message)
    this.status = status
    this.response = response
  }
}

export class Request<TResponseData = unknown> {
  private readonly config: IConfig = {}

  static makeQueryString(data: IDictionary<string | number>) {
    return data
      ? `?${Object.keys(data)
          .map((k) => `${k}=${data[k]}`)
          .join('&')}`
      : ''
  }

  async request<M extends EMethods>(
    method: M,
    url: string,
    params: TParams<M> = {},
  ): Promise<IRequestResponse<TResponseData>> {
    let finalUrl = url
    if (this.config.baseURL) {
      finalUrl = `${this.config.baseURL}${finalUrl[0] !== '/' ? '/' : ''}${finalUrl}`
    }

    const isQueryMethod = method === EMethods.get

    if (isQueryMethod) {
      finalUrl = `${finalUrl}${Request.makeQueryString(<TMethodToParams[EMethods.get]>params.params)}`
    }
    const headers = {
      ...this.config.headers,
      ...params.headers,
    }

    if (fetch) {
      const response = await fetch(finalUrl, {
        method: method,
        body: !isQueryMethod ? JSON.stringify(params.params) : null,
        headers: headers,
      })
      const data = await response.json()
      if (response.ok) {
        return {
          data: data,
          status: response.status,
        }
      }

      throw new RequestError<TResponseData>(
        `Request failed with status code ${response.status} ${response.statusText}`,
        response.status,
        data,
      )
    } else {
      return await new Promise(
        (
          resolve: (response: IRequestResponse<TResponseData>) => void,
          reject: (error: RequestError<TResponseData>) => void,
        ) => {
          const xhr = new XMLHttpRequest()
          xhr.open(method, finalUrl, true)
          Object.keys(headers).forEach((key) => {
            xhr.setRequestHeader(key, headers[key])
          })
          xhr.setRequestHeader('Content-Type', 'application/json')

          if (params.timeout) {
            xhr.timeout = params.timeout
          }

          xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
              // match 2xx status codes
              let data = xhr.response
              try {
                data = JSON.parse(xhr.response)
              } catch (e) {}

              resolve({
                data: data,
                status: xhr.status,
              })
            } else {
              reject(
                new RequestError<TResponseData>(
                  `Request failed with status code ${xhr.status} ${xhr.statusText}`,
                  xhr.status,
                  xhr.response,
                ),
              )
            }
          }

          xhr.onerror = function () {
            reject(
              new RequestError<TResponseData>(
                `Failed to send request ${xhr.status} ${xhr.statusText}`,
                xhr.status,
                xhr.response,
              ),
            )
          }

          xhr.ontimeout = function () {
            reject(new RequestError<TResponseData>('Request timed out 408 Request Timeout', 408, null))
          }

          xhr.send(JSON.stringify(params.params))
        },
      )
    }
  }

  async get(url: string, params?: TParams<EMethods.get>): Promise<IRequestResponse<TResponseData>> {
    return await this.request(EMethods.get, url, params)
  }

  async post(url: string, params?: TParams<EMethods.post>): Promise<IRequestResponse<TResponseData>> {
    return await this.request(EMethods.post, url, params)
  }

  async put(url: string, params?: TParams<EMethods.put>): Promise<IRequestResponse<TResponseData>> {
    return await this.request(EMethods.put, url, params)
  }

  replaceHeaders(headers: IDictionary<string>): void {
    this.config.headers = {
      ...this.config.headers,
      ...headers,
    }
  }

  constructor(config?: IConfig) {
    if (config) {
      if (config.baseURL && config.baseURL[config.baseURL.length - 1] === '/') {
        config.baseURL = config.baseURL.slice(0, config.baseURL.length - 1)
      }
      this.config = config
    }
  }
}
