import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { _fetchSettings } from 'store/fetchers/utils'

import { _fetchWhitelistWallets, _whitelistWallets } from 'store/fetchers/wallets'
import { ICurrency } from 'store/fetchers/currencies'

import { AppState } from '../../index'

export const storeKey = '@redux/utils'

export interface WhiteListItem {
  address: string
  extraId?: string
  currency: ICurrency
  address_name?: string
}

export interface ISettings {
  ltvOptions: string[]
  ltvDefaultOption: string
}

export interface ISettingsState {
  settings: ISettings
  whiteList: WhiteListItem[]
}

const initialState: ISettingsState = {
  settings: {
    ltvOptions: [],
    ltvDefaultOption: null,
  },
  whiteList: [],
}

export const ratesSlice = createSlice({
  name: storeKey,
  initialState,
  reducers: {
    setSettings: (state: ISettingsState, action: PayloadAction<ISettings>) => {
      state.settings = action.payload
    },
    setWhiteList: (state: ISettingsState, action: PayloadAction<WhiteListItem[]>) => {
      state.whiteList = action.payload
    },
  },
})

export const { setSettings, setWhiteList } = ratesSlice.actions

export const getSettings = (state: AppState): ISettings => state[storeKey].settings || initialState.settings
export const selectedWhiteList = (state: AppState): WhiteListItem[] =>
  state[storeKey]?.whiteList || initialState.whiteList

export const fetchSettings = (): any => async (dispatch) => {
  const data = await _fetchSettings()

  const settings = {
    ltvOptions: data.ltv_options,
    ltvDefaultOption: data.ltv_default_option,
  }
  await dispatch(setSettings(settings))
}

export const addItemToWhiteList = (params: {
  code: string
  network: string
  tag?: string
  address: string
  verification_token: string | string[]
  code_2fa: string
}): any => async (dispatch, getState) => {
  await _whitelistWallets(params)
  const addressList = await _fetchWhitelistWallets()
  await dispatch(setWhiteList([...addressList]))
}

export default ratesSlice.reducer
