import { countDecimalPlaces } from './count-decimal-places'

export function floorNumber(number: number, precision: number): number {
  return number && countDecimalPlaces(number) > precision
    ? Math.floor(number * Math.pow(10, precision)) / Math.pow(10, precision)
    : number
}

export function ceilNumber(number: number, precision: number) {
  return Math.ceil(number * Math.pow(10, precision)) / Math.pow(10, precision)
}
