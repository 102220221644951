import api from 'config/api'

import { IFormattedEarning, IFormattedEarnEvent, ITotalAmountOfSavings } from './enum'

export const createDeposit = async (params: {
  user_id?: string
  currency_code: string
  currency_network: string
  deposit: {
    expected_amount: string | number
  }
}): Promise<IFormattedEarning> => {
  try {
    const { data } = await api.post('/v2/earns/', {
      params: params,
    })
    return data.response
  } catch (ignore) {
    return null
  }
}

export const estimateDeposit = async (params: {
  currency_code: string
  currency_network: string
}): Promise<{
  annual_percent: string
}> => {
  try {
    const { data } = await api.get('/v2/earns/estimate', {
      params: params,
    })

    return data.response
  } catch (ignore) {
    return null
  }
}

export const confirmCreateDeposit = async ({
  earn_id,
  ...params
}: {
  user_id?: string
  earn_id: string
  agreed_to_tos?: boolean
}): Promise<boolean> => {
  try {
    const { data } = await api.post(`/v2/earns/${earn_id}/confirm`, {
      params: params,
    })
    return !!data.result
  } catch (ignore) {
    return false
  }
}

export const confirmCreateDepositFromAccount = async ({
  earn_id,
  ...params
}: {
  earn_id: string
  agreed_to_tos?: boolean
}): Promise<boolean> => {
  try {
    const { data } = await api.post(`/v2/earns/${earn_id}/confirm`, {
      params: { ...params, wallet: true },
    })
    return !!data.result
  } catch (ignore) {
    return false
  }
}

export const fetchUserDeposits = async (): Promise<IFormattedEarning[]> => {
  try {
    const { data } = await api.get('/v2/earns')
    return data?.response || []
  } catch (ignore) {
    return []
  }
}

export const _fetchDepositById = async ({ earn_id }: { earn_id: string }): Promise<IFormattedEarning> => {
  try {
    const { data } = await api.get(`/v2/earns/${earn_id}`)
    return data?.response
  } catch (ignore) {
    return null
  }
}

export const _fetchDepositEvents = async ({ earn_id }: { earn_id: string }): Promise<IFormattedEarnEvent[]> => {
  try {
    const { data } = await api.get(`/v2/earns/${earn_id}/events`)
    return data.response
  } catch (ignore) {
    return null
  }
}

export const increaseDepositAmount = async ({
  earn_id,
  amount,
}: {
  earn_id: string
  amount: string
}): Promise<{
  result: boolean
  response: {
    address: string
    extraId: string
  }
}> => {
  try {
    const { data } = await api.post(`/v2/earns/${earn_id}/increase`, {
      params: {
        increase: {
          expected_amount: amount,
        },
      },
    })

    return {
      result: data.result,
      response: data.response,
    }
  } catch (ignore) {
    return null
  }
}

export const increaseDepositAmountFromAccount = async ({
  earn_id,
  amount,
}: {
  earn_id: string
  amount: string
}): Promise<{
  result: boolean
  response: {
    address: string
    extraId: string
  }
}> => {
  try {
    const { data } = await api.post(`/v2/earns/${earn_id}/increase`, {
      params: {
        increase: {
          expected_amount: amount,
        },
        wallet: true,
      },
    })

    return {
      result: data.result,
      response: data.response,
    }
  } catch (ignore) {
    return null
  }
}

export const createWithdraw = async ({
  earn_id,
  ...params
}: {
  earn_id: string
  amount: string
  address?: string
  extra_id?: string
  verification_token?: string
  code_2fa?: string
}): Promise<boolean> => {
  try {
    const { data } = await api.post(`/v2/earns/${earn_id}/withdraw`, {
      params: { ...params, wallet: true },
    })
    return data.result
  } catch (e) {
    return false
  }
}

export const getTotalAmountOfSavings = async (): Promise<ITotalAmountOfSavings> => {
  try {
    const { data } = await api.get(`/v2/earns/summary`)

    return data.response
  } catch (e) {
    return null
  }
}
