import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import thunkMiddleware from './utils/thunk-middleware'

import { reducers } from './reducers'

const store = configureStore({
  reducer: reducers,
  middleware: [thunkMiddleware],
})

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>

export default store
