import { useEffect, useState } from 'react'
import { ChangeValue, FormattedData, Periods } from 'pages/account/wallets/info/wallet.types'
import { fetchRates } from 'store/reducers/rates'
import { MOCK_USD_WALLET } from 'pages/account/wallets/info/wallet.mock'
import { ICurrency } from 'store/fetchers/currencies'
import {
  calculateChange,
  calculateStartDate,
  formatData,
  getAggregate,
  getLimit,
} from 'pages/account/wallets/info/wallet.utils'
import { useAppDispatch } from 'store/hooks'
import { MONTH } from 'pages/account/wallets/info/wallet.constants'

interface UseAddRateData {
  currency: ICurrency
  period: Periods
  addTimeToFormatter?: boolean
  isDashboard?: boolean
}

export const useRateInfo = ({
  currency,
  period,
  addTimeToFormatter,
  isDashboard,
}: UseAddRateData): { actualRate: number; changeValue: ChangeValue; graphData: FormattedData[] } => {
  const dispatch = useAppDispatch()

  const [graphData, setGraphData] = useState<FormattedData[]>([])
  const [changeValue, setChangeValue] = useState<ChangeValue>()
  const [actualRate, setActualRate] = useState<number>()

  useEffect(
    function updateChartData() {
      async function loadRates() {
        if (currency && period) {
          const fetchedRates = await dispatch(
            fetchRates({
              base: currency,
              target: (MOCK_USD_WALLET as unknown) as ICurrency,
              from: calculateStartDate(period),
              minute: period !== MONTH,
              limit: getLimit(period),
              aggregate: getAggregate(period),
              isDashboard,
            }),
          )
          setGraphData(formatData(period, fetchedRates, addTimeToFormatter))
        }
      }
      loadRates()
    },
    [currency, period],
  )

  useEffect(
    function updateActualRateAndChange() {
      if (graphData.length) {
        setActualRate(graphData[graphData.length - 1].rate)
        setChangeValue(calculateChange(graphData))
      }
    },
    [graphData],
  )

  return {
    changeValue,
    actualRate,
    graphData,
  }
}
