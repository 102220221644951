import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IRequestResponse, Request } from 'libs/request'

import { ICurrency } from 'store/fetchers/currencies'

import { DEFAULT_CRYPTOCOMPARE_EXCHANGE, DEFAULT_FIAT_CURRENCY } from 'config/constants'

import { selectedWallets } from 'store/reducers/wallets'

import getConfig from 'next/config'

import { AppState } from '../../index'

export const storeKey = '@redux/rates'

export interface IRatesResponse {
  Data: Array<{
    close: number
    time: number
  }>
}

export type IPricesResponse = Record<string, Record<string, number>>

export interface IRate {
  rate: number
  time: number
  date: string
}

export type IPrace = Record<string, Record<string, number>>

export interface IRateState {
  rate: IRate[]
  prices: IPrace
}

const initialState: IRateState = {
  rate: [],
  prices: {},
}

export const ratesSlice = createSlice({
  name: storeKey,
  initialState,
  reducers: {
    setCurrentRates: (state: IRateState, action: PayloadAction<IRate[]>) => {
      state.rate = action.payload
    },
    setWalletsPrices: (state: IRateState, action: PayloadAction<IPrace>) => {
      state.prices = action.payload
    },
  },
})

export const { setCurrentRates, setWalletsPrices } = ratesSlice.actions

export const selectedRates = (state: AppState): IRate[] => state[storeKey].rate || []
export const selectedPrices = (state: AppState): IPrace => state[storeKey].prices || {}

/*
fetch rates dots for graph data
 */

const checkRateException = (sym: string): string => {
  const formattedSym = sym.toUpperCase()

  switch (formattedSym) {
    case 'XNO':
      return 'NANO'
    default:
      return formattedSym
  }
}
const { publicRuntimeConfig } = getConfig()
export const fetchRates = ({
  from,
  to,
  base,
  target,
  minute,
  limit,
  aggregate,
  isDashboard,
}: {
  from?: number | string
  to?: number | string
  base: ICurrency
  target: ICurrency
  minute?: boolean
  limit?: number
  aggregate?: number
  isDashboard?: boolean
}): any => async (dispatch) => {
  // todo any AppThunk
  try {
    let instance

    if (isDashboard) {
      instance = new Request<IRatesResponse | IPricesResponse>({
        baseURL: `https://min-api.cryptocompare.com/data`,
        headers: {
          authorization: publicRuntimeConfig.CRYPTOCURRENCY_API_KEY_DASHBOARD,
        },
      })
    } else {
      instance = new Request<IRatesResponse | IPricesResponse>({
        baseURL: 'https://min-api.cryptocompare.com/data',
      })
    }

    const fsym = checkRateException(base.display_code || base.code)
    const tsym = checkRateException(target.display_code || target.code)
    const e = base.exchange || DEFAULT_CRYPTOCOMPARE_EXCHANGE

    const defaultParameters = {
      e,
      extraParams: 'CryptoCompare',
      tryConversion: 'true',
      fsym,
      tsym,
    }

    const paramsForMinute = { ...defaultParameters, aggregate: aggregate || 1, limit: limit || 10000 }
    const paramsForDay = { ...defaultParameters, aggregate: aggregate || 1, limit: limit || 2000 }
    const paramsForHour = { ...defaultParameters, aggregate: aggregate || 4, limit: limit || 500 }

    let url
    let params

    if (minute) {
      url = '/histominute'
      params = paramsForMinute
    } else if (to) {
      url = '/histoday'
      params = paramsForDay
    } else {
      url = '/histohour'
      params = paramsForHour
    }

    let request = instance.get(url, { params }) as Promise<IRequestResponse<IRatesResponse>>

    let res = await request

    if (!(res.data.Data && res.data.Data.length && Array.isArray(res.data.Data)) && tsym === 'USD') {
      request = instance.get(url, { params: { ...params, tsym: 'USDT' } }) as Promise<IRequestResponse<IRatesResponse>>
      res = await request
    }

    const {
      data: { Data: response },
    } = res

    const toTimestamp = typeof to === 'number' ? to : new Date(to).getTime()
    const fromTimestamp = typeof from === 'number' ? from : new Date(from).getTime()

    const result: IRate[] = []
    for (const its of response) {
      if (from && its.time * 1000 <= fromTimestamp) {
        continue
      }
      if (to && its.time * 1000 >= toTimestamp) {
        continue
      }
      result.push({
        rate: its.close,
        time: its.time,
        date: new Date(its.time * 1000).toISOString(),
      })
    }

    result.sort((a, b) => a.time - b.time)

    dispatch(setCurrentRates(result))
    return result
  } catch (ignore) {
    return []
  }
}

export const fetchWalletsPrices = () => async (dispatch, getState) => {
  const wallets = selectedWallets(getState())
  const instance = new Request<IRatesResponse | IPricesResponse>({
    baseURL: 'https://min-api.cryptocompare.com/data',
  })
  if (wallets.length) {
    const fsyms = wallets.map((wallet) => wallet.code.toUpperCase()).join(',')
    const tsyms = DEFAULT_FIAT_CURRENCY
    const request = instance.get('/pricemulti', {
      params: { fsyms, tsyms },
    }) as Promise<IRequestResponse<IPricesResponse>>
    const { data: response } = await request
    dispatch(setWalletsPrices(response as IPricesResponse))
  }
}
export default ratesSlice.reducer
