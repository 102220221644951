import type { ChangeValue, FormattedData, Periods } from 'pages/account/wallets/info/wallet.types'
import type { IRate } from 'store/reducers/rates'

import {
  DAY,
  HALF_DAY,
  MONTH,
  PERIODS_IN_MS,
  THREE_DAYS,
  WEEK,
  INTERVALS,
} from 'pages/account/wallets/info/wallet.constants'

export function calculateStartDate(period: Periods): number {
  const now = new Date()

  const periodInMs = PERIODS_IN_MS[period]
  if (periodInMs !== undefined) {
    return new Date(now.getTime() - periodInMs).getTime()
  } else if (period === MONTH) {
    const previousMonth = now.getMonth() - 1
    const year = now.getFullYear()
    const dayInPreviousMonth = new Date(year, previousMonth + 1, 0).getDate()
    return new Date(year, previousMonth, dayInPreviousMonth).getTime()
  }
  return now.getTime()
}

export function formatData(period: Periods, rawData: IRate[], addTime?: boolean): FormattedData[] {
  return rawData.map((item) => {
    const date = new Date(item.date)

    if (period === HALF_DAY || period === DAY) {
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const formattedTime = `${hours}:${minutes}`
      return { rate: item.rate, date: formattedTime }
    } else if (period === THREE_DAYS || period === WEEK || period === MONTH) {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const formattedDate = `${day}.${month}${addTime ? ', ' + hours + ':' + minutes : ''}`
      return { rate: item.rate, date: formattedDate }
    }

    return { rate: item.rate, date: item.date }
  })
}

export function calculateChange(data: FormattedData[]): ChangeValue {
  const startValue = data[0].rate
  const endValue = data[data.length - 1].rate

  const changeValue = endValue - startValue
  const percentChange = ((changeValue / startValue) * 100).toFixed(2)

  if (changeValue > 0) {
    return { value: parseFloat(percentChange), type: 'INCREASE' }
  } else if (changeValue < 0) {
    return { value: Math.abs(parseFloat(percentChange)), type: 'DECREASE' }
  } else {
    return { value: 0, type: 'INCREASE' }
  }
}

export function getLimit(period: Periods): number {
  const periodInMs = PERIODS_IN_MS[period]
  const timeInterval = INTERVALS[period]
  return Math.ceil(periodInMs / timeInterval)
}

export function getAggregate(period: Periods) {
  switch (period) {
    case MONTH:
      return 1
    case WEEK:
      return 15
    case THREE_DAYS:
    case DAY:
    case HALF_DAY:
      return 5
    default:
      return 1
  }
}
