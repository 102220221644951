import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { _fetchDepositById, _fetchDepositEvents, getTotalAmountOfSavings } from 'store/fetchers/earnings'
import { EEarnEventTypes, IFormattedEarning, ITotalAmountOfSavings } from 'store/fetchers/earnings/enum'

import { AppState, AppThunk } from '../../index'

export const storeKey = '@redux/earnings'

export interface IEarningsState {
  depositEvents: any[] // todo delete
  depositEventsFiltered: any[] // todo delete
  dateFrom: Date // todo delete
  dateTo: Date // todo delete
  type: EEarnEventTypes[] // todo delete
  activeDeposit: IFormattedEarning
  totalAmountOfSavings: ITotalAmountOfSavings
}

const initialState: IEarningsState = {
  depositEvents: [],
  depositEventsFiltered: [],
  dateFrom: null,
  dateTo: null,
  type: [],
  activeDeposit: null,
  totalAmountOfSavings: null,
}

const filterDepositEvents = (events: IEarningsState['depositEvents'], state: IEarningsState) => {
  const { type, dateFrom, dateTo } = state

  let result = events

  if (type.length) {
    result = result.filter((event) => type.includes(event.type))
  }

  if (dateFrom) {
    result = result.filter((event) => event.updated_at > new Date(dateFrom).getTime())
  }

  if (dateTo) {
    result = result.filter((event) => event.updated_at < dateTo)
  }

  return result.sort((prev, curr) => curr.updated_at - prev.updated_at)
}

export const earningsSlice = createSlice({
  name: storeKey,
  initialState,
  reducers: {
    setFilteredDepositEvents: (state: IEarningsState) => {
      state.depositEventsFiltered = filterDepositEvents(state.depositEvents, state)
    },
    setTotalAmountOfSavings: (state, action) => {
      state.totalAmountOfSavings = action.payload
    },
    _setValue: <N extends keyof IEarningsState>(
      state: IEarningsState,
      action: PayloadAction<{ name: N; value: IEarningsState[N] }>,
    ) => {
      const { name, value } = action.payload
      state[name] = value
    },
    updateDeposit: (state: IEarningsState, action: PayloadAction<IEarningsState['activeDeposit']>) => {
      if (JSON.stringify(state.activeDeposit) !== JSON.stringify(action.payload)) {
        state.activeDeposit = action.payload
      }
    },
  },
})

export const { setFilteredDepositEvents, _setValue, updateDeposit, setTotalAmountOfSavings } = earningsSlice.actions

export const getUserTotalAmountOfSavings = (store) => store[storeKey].totalAmountOfSavings

export const fetchDepositEvents = ({ earn_id }): AppThunk => async (dispatch) => {
  const events = await _fetchDepositEvents({ earn_id })
  if (events) {
    dispatch(_setValue({ name: 'depositEvents', value: events }))
    dispatch(setFilteredDepositEvents())
  }
  return events
}

export const fetchTotalAmountOfSavings = (): AppThunk => async (dispatch) => {
  const data = await getTotalAmountOfSavings()
  dispatch(setTotalAmountOfSavings(data))
}

export const selectedDepositEvents = (state: AppState) => state[storeKey].depositEvents || initialState.depositEvents
export const selectedFilteredDepositEvents = (state: AppState) =>
  state[storeKey].depositEventsFiltered || initialState.depositEventsFiltered
export const selectedActiveDeposit = (state: AppState) => state[storeKey].activeDeposit || initialState.activeDeposit

/*
util function
 */

export const setValue = <N extends keyof IEarningsState>({
  value,
  name,
}: {
  name: N
  value: IEarningsState[N]
}): AppThunk => (dispatch) => {
  dispatch(_setValue({ name: name, value: value }))
  dispatch(setFilteredDepositEvents())
}

export const fetchDepositById = (params: { earn_id: string }): AppThunk<Promise<IFormattedEarning>> => async (
  dispatch,
) => {
  try {
    const data = await _fetchDepositById(params)
    if (data?.earn_id) {
      dispatch(updateDeposit(data))
      return data
    }
    return <any>{}
  } catch (ignore) {
    return {}
  }
}

export default earningsSlice.reducer
