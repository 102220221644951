export function countDecimalPlaces(num: number): number {
  const numString = num.toString()

  const dotIndex = numString.indexOf('.')

  if (dotIndex === -1) {
    return 0
  }
  
  return numString.length - dotIndex - 1
}
