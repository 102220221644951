import type { IWalletOperationsResponse, WalletType } from 'store/fetchers/wallets/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  _fetchAvailableWallets,
  _fetchWallets,
  _fetchWalletsAll,
  _inputWallet,
  _inputWalletEstimate,
  _setWalletsShowList,
  fetchOperationStatuses,
} from 'store/fetchers/wallets'
import { getCurrencies } from 'store/reducers/currencies'

import type { AppState } from '../../index'

export const storeKey = '@redux/wallets'

export interface IWalletsState {
  activeWallet: WalletType
  wallets: WalletType[]
  allWallets: WalletType[]
  availableWallets: WalletType[]
  allWalletsWithBalance: WalletType[]
  statuses: IWalletOperationsResponse[]
  amountFrom: string
  amountTo: string
  input: {
    loading: boolean
    address: string
    extraId?: string
  }
  inputEstimateError: {
    message: string
  }
  allWalletRates: { [key: string]: number }
}

const initialState: IWalletsState = {
  activeWallet: undefined,
  amountFrom: '',
  amountTo: '',
  wallets: [],
  allWallets: [],
  allWalletsWithBalance: [],
  availableWallets: [],
  statuses: [],
  input: {
    loading: false,
    address: '',
  },
  inputEstimateError: {
    message: '',
  },
  allWalletRates: {},
}

export const walletsSlice = createSlice({
  name: storeKey,
  initialState,
  reducers: {
    setWallets: (state, action) => {
      state.wallets = action.payload
    },
    setActiveWallet: (state: IWalletsState, action: PayloadAction<WalletType>) => {
      if (JSON.stringify(state.activeWallet) !== JSON.stringify(action.payload)) {
        state.activeWallet = action.payload
      }
    },
    setAvailableWallets: (state, action) => {
      state.availableWallets = action.payload
    },
    setInputLoading: (state, action) => {
      state.input.loading = action.payload
    },
    setInputAddress: (state, action) => {
      state.input.address = action.payload
    },
    setInputExtraId: (state, action) => {
      state.input.extraId = action.payload
    },
    setAmountFrom: (state, action) => {
      state.amountFrom = action.payload
    },
    setAmountTo: (state, action) => {
      state.amountTo = action.payload
    },
    setInputStoreClear: (state) => {
      state.input = { address: '', loading: false }
      state.inputEstimateError.message = ''
    },
    setInputEstimateErrorMessage: (state, action) => {
      state.inputEstimateError.message = action.payload
    },
    setAllWallets: (state, action) => {
      state.allWallets = action.payload
    },
    setAllWalletsWithBalance: (state, action) => {
      state.allWalletsWithBalance = action.payload
    },
    setStatuses: (state, action) => {
      state.statuses = action.payload
    },
    setWalletRates: (state, action) => {
      state.allWalletRates = action.payload
    },
  },
})

export const selectedWallets = (store: AppState): WalletType[] => store[storeKey].wallets
export const selectedAmountFrom = (store: AppState): string => store[storeKey].amountFrom
export const selectedAmountTo = (store: AppState): string => store[storeKey].amountTo
export const selectedActiveWallet = (state: AppState): WalletType =>
  state[storeKey].activeWallet || initialState.activeWallet
export const selectedAvailableWallets = (store: AppState): WalletType[] => store[storeKey].availableWallets
export const selectedInputLoading = (store: AppState): boolean => store[storeKey].input.loading
export const selectedInputAddress = (store: AppState): string => store[storeKey].input.address
export const selectedInputExtraId = (store: AppState): string => store[storeKey].input.extraId
export const selectedInputEstimateErrorMessage = (store: AppState): string => store[storeKey].inputEstimateError.message
export const allWallets = (store: AppState): WalletType[] => store[storeKey].allWallets
export const allWalletsWithBalance = (store: AppState): WalletType[] => store[storeKey].allWalletsWithBalance
export const allStatuses = (store: AppState): IWalletOperationsResponse[] => store[storeKey].statuses
export const allWalletRates = (store: AppState): { [key: string]: number } => store[storeKey].allWalletRates

export const {
  setActiveWallet,
  setWallets,
  setAvailableWallets,
  setInputLoading,
  setInputAddress,
  setInputExtraId,
  setInputStoreClear,
  setInputEstimateErrorMessage,
  setAllWallets,
  setAmountFrom,
  setAmountTo,
  setAllWalletsWithBalance,
  setStatuses,
  setWalletRates,
} = walletsSlice.actions

export const fetchWallets = () => async (dispatch) => {
  const wallets = await _fetchWallets()
  await dispatch(setWallets(wallets))
  await dispatch(setAllWallets(wallets))
  await dispatch(setAllWalletsWithBalance(wallets))
}

export const fetchAllWallets = () => async (dispatch) => {
  const allWallets = await _fetchWalletsAll()
  await dispatch(setAllWalletsWithBalance(allWallets))
}

export const fetchAllWalletRates = () => async (dispatch) => {
  try {
    const allWallets = await _fetchWalletsAll()
    const rates = allWallets.reduce((acc, wallet) => {
      const key = `${wallet.code}-${wallet.network}`
      acc[key] = wallet.rate
      return acc
    }, {})
    dispatch(setWalletRates(rates))
  } catch (ignore) {}
}

export const fetchAllStatuses = () => async (dispatch) => {
  try {
    const res = await fetchOperationStatuses()
    await dispatch(setStatuses(res))
  } catch (ignore) {}
}

export const fetchWalletByCodeAndNetwork = ({ code, network }) => async (dispatch) => {
  try {
    const data = await _fetchWallets()
    let find = data.find(
      (wallet) =>
        wallet.code.toLowerCase() === code.toLowerCase() && wallet.network.toLowerCase() === network.toLowerCase(),
    )
    if (!find && data.length) {
      find = data[0]
    }
    if (find) {
      dispatch(setActiveWallet(find))
    }
  } catch (ignore) {}
}

function removeDuplicates(array) {
  const uniqueObjects = new Map()

  array.forEach((obj) => {
    const key = `${obj.code}-${obj.network}`
    if (!uniqueObjects.has(key)) {
      uniqueObjects.set(key, obj)
    }
  })

  return Array.from(uniqueObjects.values())
}

export const fetchAvailableWallets = () => async (dispatch, getState) => {
  const walletsList = await _fetchAvailableWallets()

  const currencies = getCurrencies(getState())

  const availableWallets = walletsList.filter((wallet) => {
    const found = currencies.find(
      (curr) =>
        curr.code.toLowerCase() === wallet.code.toLowerCase() &&
        curr.network.toLowerCase() === wallet.network.toLowerCase(),
    )
    return !!found
  })

  const unique = removeDuplicates(availableWallets)

  await dispatch(setAvailableWallets(unique))
}

export const setWalletsShowList = ({ params }: { params: WalletType[] }) => async (dispatch) => {
  const wallets = await _setWalletsShowList({ params })
  await dispatch(setWallets(wallets))
}

export const createInputWalletTransaction = (params: { code: string; network: string; amount: string }) => async (
  dispatch,
) => {
  await dispatch(setInputLoading(true))
  try {
    const data = await _inputWallet(params)
    if (data.address) {
      dispatch(setInputAddress(data.address))
    }
    if (data.extraId) {
      dispatch(setInputExtraId(data.extraId))
    }
  } catch (e) {
    console.error(e)
  } finally {
    await dispatch(setInputLoading(false))
  }
}

export const estimateInputWalletTransaction = (params: { code: string; network: string; amount: string }) => async (
  dispatch,
) => {
  try {
    const data = await _inputWalletEstimate(params)
    if ('amountFrom' in data && 'amountTo' in data) {
      dispatch(setAmountFrom(data.amountFrom))
      dispatch(setAmountTo(data.amountTo))
    }
    if ('message' in data) {
      dispatch(setAmountFrom(''))
      dispatch(setAmountTo(''))
      dispatch(setInputEstimateErrorMessage(data.message))
    } else {
      dispatch(setInputEstimateErrorMessage(''))
    }
  } catch (e) {
    let message = 'Something went wrong'
    if ('message' in e) {
      message = e.message
    }
    if ('message' in e && e.message === 'Amount is too low') {
      message = 'Deposit amount is too low'
    }

    dispatch(setInputEstimateErrorMessage(message))
  }
}

export default walletsSlice.reducer

