export enum STORAGE_KEYS {
  AUTH_TOKEN = 'store:AUTH_TOKEN',
}

export const StorageDefaultValues = {
  [STORAGE_KEYS.AUTH_TOKEN]: '',
}

export function setStorageItem<K extends STORAGE_KEYS>(id: K, value: typeof StorageDefaultValues[K]): boolean {
  if (typeof window !== 'undefined') {
    window?.localStorage?.setItem(id, value)
    return true
  }
  return false
}

export function getStorageItem<K extends STORAGE_KEYS>(id: K): typeof StorageDefaultValues[K] {
  if (typeof window !== 'undefined') {
    return <any>window?.localStorage?.getItem(id) || null
  }
  return null
}
