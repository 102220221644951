import { FC, useEffect, useRef } from 'react'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import dynamic from 'next/dynamic'

import { useAppSelector } from 'store/hooks'
import { getActiveCodeFrom } from 'store/reducers/currencies'

import { Header } from 'components/ui/header'

import { useRouter } from 'next/router'

import { unhideChatButton } from '../../../utils/hide-chat-button'

const DogeRocket = dynamic(() => import('components/ui/doge-rocket'), { ssr: false })

export const Transition: FC<{
  location: string
  code: string
}> = ({ children, location, code = '' }) => {
  const activeCode = useAppSelector(getActiveCodeFrom)

  const isEarnPage = location.includes('earn')
  const isMainPage = location === '/'
  const isLoansPage = location.includes('loans')
  const isVipPage = location.includes('private-clients')
  const iframeObserverRef = useRef<MutationObserver | null>(null)
  const router = useRouter()

  useEffect(() => {
    const observerCallback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const iframe = document.getElementById('launcher') as HTMLIFrameElement
          if (iframe && typeof window !== 'undefined' && window.innerWidth < 765) {
            unhideChatButton()
            break // Stop observing after finding the iframe
          }
        }
      }
    }

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(observerCallback)
    iframeObserverRef.current = observer

    // Start observing the document body for added or removed child elements
    observer.observe(document.body, { childList: true, subtree: true })

    // Clean up the observer on component unmount
    return () => {
      if (iframeObserverRef.current) {
        iframeObserverRef.current.disconnect()
      }
    }
  }, [router.pathname]) // Re-run effect on pathname change

  switch (code.toLowerCase()) {
    case 'usdttrc20':
      code = 'usdt'
      break
  }

  return (
    <>
      {(isMainPage || isLoansPage) && <DogeRocket isDoge={activeCode === 'doge'} />}
      <Header isEarnPage={isEarnPage} isVipPage={isVipPage} isWalletPage={false} />
      <TransitionGroup
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <ReactTransition
          key={location}
          timeout={{
            enter: 0,
            exit: 0,
          }}
        >
          {() => <div>{children}</div>}
        </ReactTransition>
      </TransitionGroup>
    </>
  )
}
