import cn from 'classnames'

import Link from 'next/link'

import { ReactSVG } from 'react-svg'

import { useEffect, useRef, useState } from 'react'

import { useRouter } from 'next/router'

import useCopiedValue from 'hooks/use-copied-value'

import CopiedMessage from 'components/ui/copied-message'

import { doLogout } from '../../../../store/reducers/users'

import { useAppDispatch } from '../../../../store/hooks'

import { reduceWord } from '../../../../utils/reduce-word'

import { goToChat } from '../../../../utils/go-to-chat'

import { hideChatButton } from '../../../../utils/hide-chat-button'

import LogoIcon from './icons/LogoIcon.svg'

import { NavbarV2LeftProps } from './navbar-v2.props'
import styles from './styles.module.scss'

import BuySellIcon from './icons/BuySell.svg'
import ExchangeIcon from './icons/Exchange.svg'

import LoansIcon from './icons/Loans.svg'
import LogoutIcon from './icons/Logout.svg'
import RabbitIcon from './icons/Rabbit.svg'
import SavingsIcon from './icons/Savings.svg'
import SettingsIcon from './icons/Settings.svg'
import WalletIcon from './icons/Wallet.svg'
import ReferralIcon from './icons/Referral.svg'
import HoverImg from './icons/HoverImg.svg'
import LeftIcon from './icons/Left.svg'
import RightIcon from './icons/Right.svg'
import DashboardIcon from './icons/Dashboard.svg'
import SupportIcon from './icons/Support.svg'

function NavbarV2Left({ user, className }: NavbarV2LeftProps) {
  const hoverEl = useRef()
  const router = useRouter()
  const [activePosition, setActivePosition] = useState({ top: 0 })
  const [initialActiveMenuItem, setInitialActiveMenuItem] = useState('')
  const [isMore, setIsMore] = useState<boolean>(false)
  const [isCopied, handleCopy] = useCopiedValue()
  const dispatch = useAppDispatch()
  const iframeObserverRef = useRef<MutationObserver | null>(null)

  useEffect(() => {
    const currentPath = router.pathname

    if (currentPath.includes('/account/dashboard')) {
      setInitialActiveMenuItem('dashboard')
    } else if (currentPath.includes('/account/wallets')) {
      setInitialActiveMenuItem('wallets')
    } else if (currentPath.includes('/account/loans')) {
      setInitialActiveMenuItem('loans')
    } else if (currentPath.includes('/account/savings')) {
      setInitialActiveMenuItem('savings')
    } else if (currentPath.includes('/account/exchange')) {
      setInitialActiveMenuItem('exchange')
    } else if (currentPath.includes('/account/buySell')) {
      setInitialActiveMenuItem('buy-sell')
    } else if (currentPath.includes('/account/settings')) {
      setInitialActiveMenuItem('settings')
    } else if (currentPath.includes('/account/referral')) {
      setInitialActiveMenuItem('referral')
    }
  }, [router.pathname])

  async function logout() {
    await dispatch(doLogout())

    if (router.pathname.startsWith('/account')) {
      await router.push('/')
    }
  }

  useEffect(() => {
    const initialActiveLink = document.querySelector(`.${styles.menu__item}.${styles.active}`)

    if (initialActiveLink) {
      const link = initialActiveLink.closest('a')

      const linkRect = link.getBoundingClientRect()
      const containerRect = link.parentElement.parentElement.getBoundingClientRect()

      const items = document.getElementsByClassName(styles.menu__item)
      Array.prototype.forEach.call(items, (item) => {
        item.classList.remove(styles.active)
      })
      link.classList.add(styles.active)
      setActivePosition({ top: linkRect.top - containerRect.top })
    }
  }, [initialActiveMenuItem])

  const activateLink = (event) => {
    const link = event.target.closest('a')

    const linkRect = link.getBoundingClientRect()
    const containerRect = link.parentElement.parentElement.getBoundingClientRect()

    const items = document.getElementsByClassName(styles.menu__item)
    Array.prototype.forEach.call(items, (item) => {
      item.classList.remove(styles.active)
    })
    link.classList.add(styles.active)
    setActivePosition({ top: linkRect.top - containerRect.top })
  }

  const activateMobileLink = (event) => {
    let link = event.target.closest('a')

    if (!link) {
      link = event.target.closest('button')
    }

    const items = document.getElementsByClassName(styles.menu__mobile__item)
    Array.prototype.forEach.call(items, (item) => {
      item.classList.remove(styles.active)
    })
    link.classList.add(styles.active)
  }

  useEffect(() => {
    const observerCallback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const iframe = document.getElementById('launcher') as HTMLIFrameElement
          if (iframe && typeof window !== 'undefined' && window.innerWidth < 765) {
            hideChatButton()
            break // Stop observing after finding the iframe
          }
        }
      }
    }

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(observerCallback)
    iframeObserverRef.current = observer

    // Start observing the document body for added or removed child elements
    observer.observe(document.body, { childList: true, subtree: true })

    // Clean up the observer on component unmount
    return () => {
      if (iframeObserverRef.current) {
        iframeObserverRef.current.disconnect()
      }
    }
  }, [router.pathname]) // Re-run effect on pathname change

  const openSupport = () => {
    goToChat()
  }

  return (
    <nav className={cn(styles['nav-left'], className)}>
      <Link className={styles.logo} href="/" passHref>
        <a className={styles.logo}>
          <img src={LogoIcon} alt="coin rabbit logo" />
        </a>
      </Link>

      <div className={styles.user}>
        <img className={styles.user__img} src={RabbitIcon} alt="coin rabbit user image" />
        <div className={styles.user__data}>
          <p className={styles.user__email}>{user && user?.email ? user?.email : user?.phone}</p>
          <small onClick={handleCopy(user?.id)} className={styles.user__id}>
            ID: {user && user?.id ? reduceWord(user?.id, 22) : ''}
            {isCopied && <CopiedMessage className={styles.user__id__copied} />}
          </small>
        </div>
      </div>

      <div className={styles.menu}>
        <div className={styles.menu__top}>
          <Link href="/account/dashboard" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'dashboard' })}
              onClick={activateLink}
            >
              <ReactSVG src={DashboardIcon} className={styles.icon} />
              <span>Dashboard</span>
            </a>
          </Link>

          <Link href="/account/wallets" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'wallets' })}
              onClick={activateLink}
            >
              <ReactSVG src={WalletIcon} className={styles.icon} />
              <span>Wallets</span>
            </a>
          </Link>

          <Link href="/account/loans" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'loans' })}
              onClick={activateLink}
            >
              <ReactSVG src={LoansIcon} className={styles.icon} />
              <span>Loans</span>
            </a>
          </Link>

          <Link href="/account/savings" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'savings' })}
              onClick={activateLink}
            >
              <ReactSVG src={SavingsIcon} className={styles.icon} />
              <span>Savings</span>
            </a>
          </Link>

          <Link href="/account/exchange" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'exchange' })}
              onClick={activateLink}
            >
              <ReactSVG src={ExchangeIcon} className={styles.icon} />
              <span>Exchange</span>
            </a>
          </Link>

          <Link href="/account/buySell" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'buy-sell' })}
              onClick={activateLink}
            >
              <ReactSVG src={BuySellIcon} className={styles.icon} />
              <span>Buy/Sell</span>
            </a>
          </Link>

          <Link href="/account/referral" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'referral' })}
              onClick={activateLink}
            >
              <ReactSVG src={ReferralIcon} className={styles.icon} />
              <span>Referral</span>
            </a>
          </Link>

          <Link href="/account/settings" passHref>
            <a
              className={cn(styles.menu__item, { [styles.active]: initialActiveMenuItem === 'settings' })}
              onClick={activateLink}
            >
              <ReactSVG src={SettingsIcon} className={styles.icon} />
              <span>Settings</span>
            </a>
          </Link>

          <ReactSVG
            ref={hoverEl}
            src={HoverImg}
            className={styles['hover-el']}
            style={{ transform: `translateY(${activePosition.top}px)` }}
          />
        </div>

        <button className={cn(styles.menu__item, styles.menu__item_logout)} onClick={logout}>
          <ReactSVG src={LogoutIcon} className={styles.icon} />
          <span>Log out</span>
        </button>
      </div>

      <div className={styles.menu__mobile}>
        <div className={cn(styles.menu__mobile_less, { [styles.hide]: isMore })}>
          <Link href="/account/dashboard" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'dashboard' })}
              onClick={activateMobileLink}
            >
              <ReactSVG src={DashboardIcon} className={styles.icon} />
              <span>Dashboard</span>
            </a>
          </Link>

          <Link href="/account/wallets" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'wallets' })}
              onClick={(event) => {
                activateMobileLink(event)
              }}
            >
              <ReactSVG src={WalletIcon} className={styles.icon} />
              <span>Wallets</span>
            </a>
          </Link>

          <Link href="/account/loans" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'loans' })}
              onClick={activateMobileLink}
            >
              <ReactSVG src={LoansIcon} className={styles.icon} />
              <span>Loans</span>
            </a>
          </Link>

          <Link href="/account/savings" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'savings' })}
              onClick={activateMobileLink}
            >
              <ReactSVG src={SavingsIcon} className={styles.icon} />
              <span>Savings</span>
            </a>
          </Link>

          <Link href="/account/exchange" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'exchange' })}
              onClick={activateMobileLink}
            >
              <ReactSVG src={ExchangeIcon} className={styles.icon} />
              <span>Exchange</span>
            </a>
          </Link>
        </div>

        <div className={cn(styles.menu__mobile_more, { [styles.show]: isMore })}>
          <Link href="/account/buySell" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'buy-sell' })}
              onClick={activateMobileLink}
            >
              <ReactSVG src={BuySellIcon} className={styles.icon} />
              <span>Buy/Sell</span>
            </a>
          </Link>

          <Link href="/account/referral" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'referral' })}
              onClick={activateMobileLink}
            >
              <ReactSVG src={ReferralIcon} className={styles.icon} />
              <span>Referral</span>
            </a>
          </Link>

          <button
            className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'support' })}
            onClick={openSupport}
          >
            <ReactSVG src={SupportIcon} className={styles.icon} />
            <span>Support</span>
          </button>

          <Link href="/account/settings" passHref>
            <a
              className={cn(styles.menu__mobile__item, { [styles.active]: initialActiveMenuItem === 'settings' })}
              onClick={activateMobileLink}
            >
              <ReactSVG src={SettingsIcon} className={styles.icon} />
              <span>Settings</span>
            </a>
          </Link>

          <button className={cn(styles.menu__mobile__item)} onClick={logout}>
            <ReactSVG src={LogoutIcon} className={styles.icon} />
            <span>Log out</span>
          </button>
        </div>

        <button
          className={cn(styles.menu__mobile__item, styles.menu__mobile__item_more)}
          onClick={(event) => {
            activateMobileLink(event)
            setIsMore(!isMore)
          }}
        >
          <ReactSVG src={isMore ? LeftIcon : RightIcon} className={styles.icon} />
          <span>{isMore ? 'Back' : 'More'}</span>
        </button>
      </div>
    </nav>
  )
}

export default NavbarV2Left
