import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchCurrencies } from 'store/reducers/currencies'
import { LoginStatuses, selectedAuthStatus, selectedErrAuthCode, selectedUser } from 'store/reducers/users'

import { Bounce, ToastContainer, toast } from 'react-toastify'

import NavbarV2Left from '../../components/ui/account/nabvar-v2-left'

import NavbarV2Right from '../../components/ui/account/navbar-v2-right'

import { fetchUserLoansAmount, getUserLoans } from '../../store/reducers/loans'

import { allWallets, fetchWallets } from '../../store/reducers/wallets'

import { fetchTotalAmountOfSavings, getUserTotalAmountOfSavings } from '../../store/reducers/earnings'

import ProblemIcon from './icons/problem.svg'

import styles from './styles.module.scss'

import 'react-toastify/dist/ReactToastify.css'
import Popup from '../../components/ui/popup'

import ButtonBlue from '../../components/ui/button/_color/_blue'

export default function AccountLayout({ children }) {
  const authStatus = useAppSelector(selectedAuthStatus)
  const user = useAppSelector(selectedUser)
  const errCode = useAppSelector(selectedErrAuthCode)
  const [isWalletsLoading, setIsWalletsLoading] = useState<boolean>(true)
  const [isSavingsLoading, setIsSavinsLoading] = useState<boolean>(true)
  const [errorAuthPopupOpened, setErrorAuthPopupOpened] = useState<boolean>(false)

  const [isLoansLoading, setIsLoansLoading] = useState<boolean>(true)

  const intervalRef = useRef<number | null>(null)
  const isLoaded = useRef<boolean>(false)

  const userLoans = useAppSelector(getUserLoans)
  const wallets = useAppSelector(allWallets)
  const totalAmountOfSavings = useAppSelector(getUserTotalAmountOfSavings)
  const isShowed = useRef<boolean>(false)

  const dispatch = useAppDispatch()
  const [errMsg, setErrMsg] = useState<string>(null)
  const [errHeading, setErrHeading] = useState<string>(null)

  const router = useRouter()

  useEffect(() => {
    if (user && user.toaster_text && sessionStorage.getItem('isToasterShowed') !== 'True' && !isShowed.current) {
      notify(user.toaster_category ?? 'info')
      isShowed.current = true
    }
  }, [user])

  useEffect(() => {
    if (authStatus === LoginStatuses.ERR) {
      setErrorAuthPopupOpened(true)
    } else {
      setErrorAuthPopupOpened(false)
    }
  }, [authStatus])

  const notify = (category: string) => {
    switch (category) {
      case 'success':
        toast.success(
          <div>
            <h4>{user.toaster_title ?? 'Success'}</h4>
            <p className={styles.toastText}>{user.toaster_text}</p>
          </div>,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
            onClose: () => {
              sessionStorage.setItem('isToasterShowed', 'True')
            },
          },
        )
        break
      case 'info':
        toast.info(
          <div>
            <h4>{user.toaster_title ?? 'Info'}</h4>
            <p className={styles.toastText}>{user.toaster_text}</p>
          </div>,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
            onClose: () => {
              sessionStorage.setItem('isToasterShowed', 'True')
            },
          },
        )
        break
      case 'warning':
        toast.warning(
          <div>
            <h4>{user.toaster_title ?? 'Warning'}</h4>
            <p className={styles.toastText}>{user.toaster_text}</p>
          </div>,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
            onClose: () => {
              sessionStorage.setItem('isToasterShowed', 'True')
            },
          },
        )
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (authStatus === LoginStatuses.LOGOUT) {
      void router.push('/user/sign-in')
    }
  }, [authStatus])

  useEffect(() => {
    if (userLoans) {
      setIsLoansLoading(false)
    }
  }, [userLoans])

  useEffect(() => {
    if (totalAmountOfSavings) {
      setIsSavinsLoading(false)
    }
  }, [totalAmountOfSavings])

  useEffect(() => {
    if (wallets && wallets?.length) {
      setIsWalletsLoading(false)
    }
  }, [wallets])

  const fetchData = () => {
    dispatch(fetchUserLoansAmount())
    dispatch(fetchWallets())
    dispatch(fetchTotalAmountOfSavings())
    dispatch(fetchCurrencies())
  }

  const fetchDataWithoutCurrencies = () => {
    dispatch(fetchUserLoansAmount())
    dispatch(fetchWallets())
    dispatch(fetchTotalAmountOfSavings())
  }

  useEffect(() => {
    switch (errCode) {
      case '0':
        setErrMsg(
          `Please check your internet connection and try reloading the page. If the issue persists, contact our support team for assistance`,
        )
        setErrHeading('Unable to connect to the server')
        break
      case '500':
        setErrMsg(`Please try reloading the page. If the issue persists, contact our support team for assistance`)
        setErrHeading('An error occurred while attempting to retrieve your account information')
    }
  }, [errCode])

  useEffect(() => {
    let initialTimeout = null
    if (authStatus === LoginStatuses.LOGIN) {
      if (!isLoaded.current) {
        const now = new Date()

        // Вычисляем количество миллисекунд до ближайших 10 секунд
        const delay = 10000 - (now.getTime() % 10000)
        fetchData()

        initialTimeout = setTimeout(() => {
          intervalRef.current = window.setInterval(fetchDataWithoutCurrencies, 10000)
        }, delay)
        isLoaded.current = true
      }
    }

    if (typeof document !== 'undefined' && authStatus === LoginStatuses.ERR) {
      setErrorAuthPopupOpened(true)
    } else {
      setErrorAuthPopupOpened(false)
    }

    return () => {
      if (initialTimeout) {
        clearTimeout(initialTimeout)
      }
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    }
  }, [authStatus])

  useEffect(() => {
    dispatch(fetchCurrencies())
  }, [])

  return (
    <div className={styles.account}>
      {errorAuthPopupOpened && (
        <Popup heading="Houston, we have a problem" isWithoutClose onClose={() => null}>
          <div className={styles.errWrapper}>
            <div className={styles.errWrapper__content}>
              <img src={ProblemIcon} alt="warning icon" />
              <div className={styles.text}>
                <h3>{errHeading}</h3>
                <p>{errMsg}</p>
              </div>
            </div>
            {errCode !== '0' && (
              <ButtonBlue className={styles.goToMainPageBtn} onClick={() => router.push('/')}>
                Go to main page
              </ButtonBlue>
            )}
          </div>
        </Popup>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {authStatus !== LoginStatuses.ERR && (
        <div className={styles.main}>
          <NavbarV2Left className={styles.left} user={user} />
          <div className={styles.content}>{children}</div>
          <NavbarV2Right
            savings={totalAmountOfSavings}
            wallets={wallets}
            isWalletsLoading={isWalletsLoading}
            isLoansLoading={isLoansLoading}
            isSavingsLoading={isSavingsLoading}
            loans={userLoans}
            className={styles.right}
          />
        </div>
      )}
    </div>
  )
}
