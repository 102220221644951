import { FC, ReactElement, useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { ScriptProps } from 'next/script'
import { NextRouter } from 'next/dist/shared/lib/router/router'
import { CookiesProvider } from 'react-cookie'

import store from 'store/index'
import { checkUserLogin } from 'store/reducers/users'
import Footer from 'components/ui/footer'
import { Transition } from 'components/global/transition'

import { getGeolocation } from '../store/fetchers/utils'

import { switchToTelegram } from '../utils/go-to-chat'

import AccountLayout from './account/layout'

import '../styles/fonts.css'
import '../styles/globals.css'
import '../styles/calendar.css'
import '../styles/variables.scss'
import '../styles/phone-input.scss'
import ErrorBoundary from './error-boundary'

function UserProvider(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      void dispatch(checkUserLogin())
    }
  }, [])

  return props.children
}

export default function App({
  Component,
  pageProps,
  router,
}: {
  Component: FC
  pageProps: ScriptProps
  router: NextRouter
}): ReactElement {
  const code = Array.isArray(router.query.coin) ? router.query.coin[0] : router.query.coin

  useEffect(() => {
    getLocation()
  }, [])

  const getLocation = async () => {
    const location = await getGeolocation()
    if (location && location.response?.toLowerCase() === 'ru') {
      switchToTelegram()
    }
  }

  if (router.pathname.startsWith('/account')) {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <UserProvider>
            <AccountLayout>
              <Component {...pageProps} />
            </AccountLayout>
          </UserProvider>
        </Provider>
      </ErrorBoundary>
    )
  }

  return (
    <>
      <CookiesProvider>
        <Provider store={store}>
          <UserProvider>
            <Transition location={router.pathname} code={code}>
              <Component {...pageProps} />
            </Transition>
          </UserProvider>
        </Provider>
        <Footer />
      </CookiesProvider>
    </>
  )
}
