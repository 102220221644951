import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getUser } from 'store/fetchers/users'
import { getStorageItem, setStorageItem, STORAGE_KEYS } from 'store/reducers/storage'
import type { IFormattedUser } from 'store/fetchers/users/types'
import type { AppState, AppThunk } from 'store'
import { setApiUserToken } from 'config/api'

export const storeKey = '@redux/user'

export enum LoginStatuses {
  LOGIN = 'LOGIN',
  INIT = 'INIT',
  LOGOUT = 'LOGOUT',
  ERR = 'ERR',
}

export interface IUser {
  id: string
  email: string
  phone: string
  isActive2Fa: boolean
  referral_code?: string
  toaster_text?: string
  toaster_category?: string
  toaster_title?: string
  fallback_email?: string
}

export interface IRateState {
  user: IUser
  authToken: string
  authStatus: LoginStatuses
  errAuthCode?: string
}

const initialState: IRateState = {
  user: null,
  authToken: getStorageItem(STORAGE_KEYS.AUTH_TOKEN),
  authStatus: LoginStatuses.INIT,
  errAuthCode: null,
}

export const ratesSlice = createSlice({
  name: storeKey,
  initialState,
  reducers: {
    setUserData: (state: IRateState, action: PayloadAction<IFormattedUser>) => {
      if (action.payload) {
        state.user = {
          phone: action.payload.phone,
          email: action.payload.email,
          id: action.payload.id,
          isActive2Fa: action.payload.is_active_2fa,
          toaster_text: action.payload.toaster_text,
          toaster_category: action.payload.toaster_category,
          toaster_title: action.payload.toaster_title,
          fallback_email: action.payload.fallback_email,
        }
      } else {
        state.user = initialState.user
      }
    },
    setAuthStatus: (state: IRateState, action: PayloadAction<LoginStatuses>) => {
      state.authStatus = action.payload
    },
    setUserToken: (state: IRateState, action: PayloadAction<string>) => {
      state.authToken = action.payload
      setStorageItem(STORAGE_KEYS.AUTH_TOKEN, action.payload)
    },
    setErrAuthCode: (state: IRateState, action: PayloadAction<string>) => {
      state.errAuthCode = action.payload
    },
  },
})

export const { setUserData, setUserToken, setAuthStatus, setErrAuthCode } = ratesSlice.actions

export const selectedUser = (state: AppState) => state[storeKey].user || initialState.user
export const selectedUserAuthToken = (state: AppState) => state[storeKey].authToken || initialState.authToken
export const selectedAuthStatus = (state: AppState): LoginStatuses => state[storeKey].authStatus
export const selectedErrAuthCode = (state: AppState): string => state[storeKey].errAuthCode

export const fetchUser = (): AppThunk => async (dispatch) => {
  try {
    const user = await getUser()
    await dispatch(setUserData(user))
  } catch (err) {
    if (err.message.includes('Failed to fetch')) {
      await dispatch(setAuthStatus(LoginStatuses.ERR))
      await dispatch(setErrAuthCode('0'))
    } else if (err.message.includes('500')) {
      await dispatch(setAuthStatus(LoginStatuses.ERR))
      await dispatch(setErrAuthCode('500'))
    } else if (err.message.includes('403')) {
      await dispatch(setAuthStatus(LoginStatuses.LOGOUT))
    }
  }
}

export const checkUserLogin = (): AppThunk => async (dispatch, getState) => {
  const token = getStorageItem(STORAGE_KEYS.AUTH_TOKEN)
  if (!token) {
    await dispatch(setUserData(null))
    await dispatch(setAuthStatus(LoginStatuses.LOGOUT))
    return
  }

  if (token) {
    await dispatch(setUserAuthToken(token))
    const user = selectedUser(getState())

    if (!user) {
      await dispatch(setUserData(null))
    } else {
      await dispatch(setAuthStatus(LoginStatuses.LOGIN))
    }
  }
}

export const setUserAuthToken = (token: string): AppThunk<Promise<void>> => async (dispatch) => {
  await dispatch(setUserToken(token))
  await setApiUserToken(token)
  if (token) {
    await dispatch(setAuthStatus(LoginStatuses.LOGIN))
    await dispatch(fetchUser())
  }
}

export const doLogout = (): AppThunk<Promise<void>> => async (dispatch) => {
  await dispatch(setAuthStatus(LoginStatuses.LOGOUT))
  await dispatch(setUserToken(''))
  await dispatch(setUserData(null))
  localStorage.setItem('store:AUTH_TOKEN', '')
  setApiUserToken('')
}

export default ratesSlice.reducer
