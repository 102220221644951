import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IFormattedLoan, LoansSummary } from 'store/fetchers/loans/enum'

import { _fetchLoanById, getTotalAmountOfLoans, fetchUserLoans, getAllTransactions } from 'store/fetchers/loans'

import { Transactions } from 'pages/account/dashboard/transaction-tab/interfaces'

import { AppState, AppThunk } from '../../index'

export const storeKey = '@redux/loans'

const LOAN_BASE = {
  loan: {},
  deposit: {},
  increase: {},
  repayment: {},
  interests_amount: {},
}

export interface ILoansState {
  activeLoan: IFormattedLoan
  loans: IFormattedLoan[]
  userLoans: LoansSummary
  allTransactions: Transactions[]
}

const initialState: ILoansState = {
  activeLoan: <any>LOAN_BASE,
  loans: [],
  userLoans: null,
  allTransactions: [],
}

export const loansSlice = createSlice({
  name: storeKey,
  initialState,
  reducers: {
    setLoans: (state, action) => {
      state.loans = action.payload
    },
    updateLoan: (state: ILoansState, action: PayloadAction<IFormattedLoan>) => {
      if (JSON.stringify(state.activeLoan) !== JSON.stringify(action.payload)) {
        state.activeLoan = action.payload
      }
    },
    setUserLoans: (state: ILoansState, action: PayloadAction<LoansSummary>) => {
      state.userLoans = action.payload
    },
    setAllTransactions: (state, action: PayloadAction<Transactions[]>) => {
      state.allTransactions = action.payload
    },
  },
})

export const getLoans = (store) => store[storeKey].loans
export const getUserLoans = (store) => store[storeKey].userLoans
export const getTransactions = (store: AppState) => store[storeKey].allTransactions
export const selectedActiveLoan = (state: AppState) => state[storeKey].activeLoan || initialState.activeLoan

export const { updateLoan, setLoans, setUserLoans, setAllTransactions } = loansSlice.actions

export const fetchLoans = () => async (dispatch, getState) => {
  let loans = getLoans(getState())

  // if (loans.length) {
  //   return
  // }

  loans = await fetchUserLoans()

  await dispatch(setLoans(loans))
}

export const fetchUserLoansAmount = (): AppThunk => async (dispatch) => {
  try {
    const userLoans = await getTotalAmountOfLoans()
    dispatch(setUserLoans(userLoans))
  } catch (err) {
    return null
  }
}

export const fetchLoanById = (params: { loan_id: string }): AppThunk => async (dispatch) => {
  try {
    const data = await _fetchLoanById(params)
    if (data?.loan_id) {
      dispatch(updateLoan(data))
    }
    return data
  } catch (ignore) {
    return null
  }
}

export const fetchAllTransactions = (): AppThunk => async (dispatch) => {
  try {
    const transactions = await getAllTransactions()
    dispatch(setAllTransactions(transactions))
  } catch (ignore) {
    return null
  }
}

export default loansSlice.reducer
