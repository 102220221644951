import { useRateInfo } from '../../../../../pages/account/wallets/info/components/chart/use/useRateInfo'
import { DAY } from '../../../../../pages/account/wallets/info/wallet.constants'
import { formatNumberWithSpaces } from '../../../../../utils/format-number-with-spaces'

import { NavbarRightCurrencyProps } from './navbar-right-currency.props'
import styles from './styles.module.scss'

export default function NavbarRightCurrency({ currency }: NavbarRightCurrencyProps) {
  const { actualRate, changeValue } = useRateInfo({ currency, period: DAY, isDashboard: true })

  return (
    <div className={styles.coin}>
      <img src={currency.logo_url} alt="coin icon" />
      <div className={styles.coin__content}>
        <h3>{currency.code.toUpperCase()}</h3>
        <p>
          ${formatNumberWithSpaces(actualRate)}
          <span className={changeValue && changeValue.type === 'INCREASE' ? styles.green : styles.red}>
            {changeValue && changeValue.type === 'INCREASE' ? '↑' : '↓'}%{changeValue?.value}
          </span>
          <span className={styles.gray}>24h</span>
        </p>
      </div>
    </div>
  )
}
