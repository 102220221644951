// components/ErrorBoundary.tsx
import { Component, ErrorInfo, ReactNode } from 'react';

import { sendSlackNotification } from '../store/fetchers/utils';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  // eslint-disable-next-line prettier/prettier
  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('ErrorBoundary caught an error', error, errorInfo);

    const url = window.location.href;
    const date = new Date().toLocaleString();
    const errorMessage = `:warning: Client-Side exception: \`${error.message}\`, stack: ${error.stack}
    URL: ${url}, Date: ${date}`;

    sendSlackNotification({
      message: errorMessage,
      channel: 'front',
    });
  };

  override render() {
    if (this.state.hasError) {
      // Render a more customized fallback UI
      return (
        <div style={{ padding: '20px', textAlign: 'center', backgroundColor: '#f8d7da', color: '#721c24', borderRadius: '4px' }}>
          <h1>Oops! Something went wrong.</h1>
          <p>We’re working on fixing the issue. Please refresh the page or try again later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
