import { WalletType } from 'store/fetchers/wallets/types'

export const MOCK_USD_WALLET: WalletType = {
  code: 'USD',
  network: 'ETH',
  balance: 100.07513705,
  pendingBalance: 50.2,
  hidden: false,
  transactions: [],
}

export const MOCK_TX_LIST = [
  {
    label: 'Receive',
    network: 'ETH',
    code: 'USDT',
    hash: '0x3100375e9a1ae68d15d3b2a53b629c61cb7311a7571cadedf5dec01bf27ea64f',
    amount: '300',
    incoming: true,
    date: 'Sat May 22 2021 14:45:32 GMT+0400',
    status: 'SUCCESS',
    type: 'DEPOSIT',
  },
  {
    label: 'Daily interest earned',
    network: 'ETH',
    code: 'USDT',
    hash: '0x79de5c25c3f74b2e54964e6e415447a8f15227ed65124fe2de0341ac3ca74b49',
    amount: '0.33',
    incoming: true,
    date: 'Sat Aug 22 2021 14:45:32 GMT+0400',
    status: 'SUCCESS',
    type: 'DEPOSIT',
  },
  {
    label: 'Withdraw deposit',
    network: 'ETH',
    code: 'USDT',
    hash: '0xc68a4bb77ced56046405157c69f0881a5e7d3ae7bf2c18b49355d5a1cb710e39',
    amount: '500',
    incoming: false,
    date: 'Sat Aug 22 2021 14:45:32 GMT+0400',
    status: 'FAILED',
    type: 'WITHDRAW',
  },
]
