import { useEffect, useState } from 'react'

const useWindowWidth = (): number => {
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    const handleWindowSizeChange = (): void => {
      setWidth(window.innerWidth)
    }

    if (window) {
      setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowSizeChange)
    }

    return (): void => {
      if (window) {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])

  return width
}

export default useWindowWidth
