import { FC, useEffect } from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

export const PopupWrapper: FC<{
  onClose: () => void
  classNames?: string | string[]
}> = ({ children, onClose, classNames = [] }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <div className={styles.background} onMouseDown={onClose}>
      <div className={cn(styles.container, ...classNames)} id="popup" onMouseDown={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}
