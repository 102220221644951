import { FC, useCallback, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import { useRouter } from 'next/router'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { doLogout, LoginStatuses, selectedAuthStatus } from 'store/reducers/users'
import scrollToElement from 'utils/scroll-to-element'
import useViewport from 'libs/use-viewport'

import Logotype from 'components/ui/logotype/logotype.svg'
import WhiteLogotype from 'components/ui/logotype/Vector.svg'
// import AccountDropdown from 'components/ui/header/components/account-dropdown'
import ButtonBlue from 'components/ui/button/_color/_blue'

import useOnClickOutside from '../../../hooks/use-on-click-outside'

import LogoWallet from './icons/logo_wallet.svg'
import LogoWhite from './icons/logo-white.svg'

import IconWalletInverted from './icons/icon-wallet-inverted.svg'
import IconLogout from './icons/logout-icon-inverted.svg'
import IconSettings from './icons/settings-icon-inverted.svg'

import styles from './styles.module.scss'

interface Props {
  isEarnPage: boolean
  className?: string
  isWalletPage?: boolean
  isVipPage?: boolean
}

export const Header: FC<Props> = ({ isEarnPage, className, isVipPage = false, isWalletPage = false }) => {
  const [isNavOpened, setIsNavOpened] = useState(false)
  const [isLinksOpened, setIsLinksOpened] = useState(false)
  const [isInverted, setIsInverted] = useState(false)

  const authStatus = useAppSelector(selectedAuthStatus)

  const ref = useRef(null)

  const dispatch = useAppDispatch()
  const router = useRouter()
  const [viewport] = useViewport()

  const handleLogout = async () => {
    setIsNavOpened(false)
    await dispatch(doLogout())

    if (router.pathname.startsWith('/account')) {
      await router.push('/')
    }
  }

  // for scrolling how it works block in src/components/ui/header/index.p.tsx
  const howItWorks = useCallback(async () => {
    await router.push('/')
    scrollToElement('how-it-works')
    setIsNavOpened(false)
  }, [])

  const toggleNavOpened = () => {
    setIsNavOpened(!isNavOpened)
  }

  const handleNavClose = () => {
    if (isNavOpened) setIsNavOpened(false)
  }

  const goToAccount = () => {
    handleNavClose()
    router.push('/account/dashboard')
  }

  useOnClickOutside(ref, () => setIsLinksOpened(false))

  useEffect(handleNavClose, [viewport])

  useEffect(() => {
    if (isNavOpened !== isInverted) setIsInverted(isNavOpened)
  }, [isNavOpened, isInverted])

  return (
    <>
      <div
        className={cn(styles.header, className, {
          [styles.headerOpened]: isNavOpened,
          [styles.header_wallet]: isWalletPage,
          [styles.vipPage]: isVipPage,
        })}
        id="header"
      >
        <div className={cn('container', styles.headerWrapper)}>
          <div className={styles.headerLogo}>
            <Link prefetch={false} href={router.pathname.includes('earn') ? '/earn' : '/'}>
              {!isWalletPage ? (
                !isVipPage ? (
                  <a>
                    <img src={Logotype} alt="CoinRabbit" width={162} height={33} className={styles.desktopOnly} />
                    <img src={WhiteLogotype} alt="CoinRabbit" width={162} height={33} className={styles.mobileOnly} />
                  </a>
                ) : (
                  <a>
                    <img src={LogoWhite} alt="CoinRabbit" width={162} height={33} />
                  </a>
                )
              ) : (
                <a>
                  <img src={LogoWallet} alt="CoinRabbit" width={162} height={33} />
                </a>
              )}
            </Link>
          </div>
          <div className={styles.menuToggleBtnWrapper}>
            <div
              onClick={toggleNavOpened}
              className={cn(styles.menuToggleBtn, {
                [styles.menuToggleBtnOpened]: isNavOpened,
                [styles.menuToggleBtnInverted]: isInverted,
              })}
            >
              <div />
            </div>
          </div>
          <div
            className={cn(
              styles.headerLinks,
              { [styles.headerLinks_wallet]: isWalletPage },
              { [styles.headerLinks_vip]: isVipPage },
            )}
          >
            <Link prefetch={false} href="/">
              <a
                className={cn(styles.navLink, styles.link_mobile, styles.navLink_main, {
                  [styles.navLink_wallet]: isWalletPage,
                })}
                onClick={handleNavClose}
              >
                Crypto loans
              </a>
            </Link>
            <Link prefetch={false} href="/earn">
              <a
                className={cn(styles.navLink, styles.link_mobile, { [styles.navLink_wallet]: isWalletPage })}
                onClick={handleNavClose}
              >
                Savings
              </a>
            </Link>
            <Link prefetch={false} href="/exchange">
              <a
                className={cn(styles.navLink, styles.link_mobile, { [styles.navLink_wallet]: isWalletPage })}
                onClick={handleNavClose}
              >
                Exchange
              </a>
            </Link>
            <Link prefetch={false} href="/wallet">
              <a
                className={cn(styles.navLink, styles.link_mobile, { [styles.navLink_wallet]: isWalletPage })}
                onClick={handleNavClose}
              >
                Wallet
              </a>
            </Link>
            <Link prefetch={false} href="/referral">
              <a
                className={cn(styles.navLink, styles.link_mobile, { [styles.navLink_wallet]: isWalletPage })}
                onClick={handleNavClose}
              >
                Invite & Earn
              </a>
            </Link>

            <Link prefetch={false} href="/private-clients">
              <a
                className={cn(styles.navLink, styles.link_mobile, { [styles.navLink_wallet]: isWalletPage })}
                onClick={handleNavClose}
              >
                Private
              </a>
            </Link>
            <div className={styles.navLinks}>
              <div
                className={cn(
                  styles.navLink,
                  styles.navLink_main,
                  styles.navLink_group,
                  {
                    [styles.navLink_wallet]: isWalletPage,
                  },
                  {
                    [styles.navLink_group_wallet]: isWalletPage,
                  },
                  {
                    [styles.opened]: isLinksOpened,
                  },
                  {
                    [styles.navLink_group_vip]: isVipPage,
                  },
                )}
                onClick={() => setIsLinksOpened(!isLinksOpened)}
              >
                Products
                <div className={cn(styles.link__groups)} ref={ref}>
                  <Link prefetch={false} href="/">
                    <a
                      className={cn(styles.navLink, styles.navLink_main, { [styles.navLink_wallet]: isWalletPage })}
                      onClick={handleNavClose}
                    >
                      Crypto loans
                    </a>
                  </Link>

                  <Link prefetch={false} href="/earn">
                    <a
                      className={cn(styles.navLink, styles.navLink_main, { [styles.navLink_wallet]: isWalletPage })}
                      onClick={handleNavClose}
                    >
                      Savings
                    </a>
                  </Link>

                  <Link prefetch={false} href="/exchange">
                    <a
                      className={cn(styles.navLink, styles.navLink_main, { [styles.navLink_wallet]: isWalletPage })}
                      onClick={handleNavClose}
                    >
                      Exchange
                    </a>
                  </Link>

                  <Link prefetch={false} href="/wallet">
                    <a
                      className={cn(styles.navLink, styles.navLink_main, { [styles.navLink_wallet]: isWalletPage })}
                      onClick={handleNavClose}
                    >
                      Wallet
                    </a>
                  </Link>
                  <Link prefetch={false} href="/referral">
                    <a
                      className={cn(styles.navLink, styles.navLink_main, { [styles.navLink_wallet]: isWalletPage })}
                      onClick={handleNavClose}
                    >
                      Invite & Earn
                    </a>
                  </Link>

                  <Link prefetch={false} href="/private-clients">
                    <a
                      className={cn(styles.navLink, styles.navLink_main, { [styles.navLink_wallet]: isWalletPage })}
                      onClick={handleNavClose}
                    >
                      Private
                    </a>
                  </Link>
                </div>
              </div>

              <Link prefetch={false} href="/partners">
                <a
                  className={cn(styles.navLink, styles.navLink_main, { [styles.navLink_wallet]: isWalletPage })}
                  onClick={handleNavClose}
                >
                  For partners
                </a>
              </Link>
              <Link prefetch={false} href="/about">
                <a className={cn(styles.navLink, { [styles.navLink_wallet]: isWalletPage })} onClick={handleNavClose}>
                  About Us
                </a>
              </Link>
              {isEarnPage ? (
                <a
                  href="https://coinrabbit.io/blog/coinrabbit-earnings-interest-review"
                  className={cn(styles.headerHowItWorks, styles.navLink, { [styles.navLink_wallet]: isWalletPage })}
                >
                  How it works
                </a>
              ) : (
                <Link prefetch={false} href="/">
                  <a
                    className={cn(styles.headerHowItWorks, styles.navLink, { [styles.navLink_wallet]: isWalletPage })}
                    onClick={howItWorks}
                  >
                    How it works
                  </a>
                </Link>
              )}
              <a
                href="/blog/"
                target="_blank"
                className={cn(styles.navLink, { [styles.navLink_wallet]: isWalletPage })}
                onClick={handleNavClose}
              >
                Blog
              </a>
            </div>

            {(authStatus === LoginStatuses.LOGOUT || authStatus === LoginStatuses.INIT) && (
              <div className={styles.navLinks}>
                <button
                  type="button"
                  className={cn(styles.headerLinksBox, styles.signInButton, styles.navLink)}
                  onClick={() => {
                    router.push('/user/sign-in')
                    handleNavClose()
                  }}
                >
                  <span
                    className={cn(
                      { [styles.inverted]: isInverted },
                      { [styles.signInWallet]: isWalletPage },
                      { [styles.signInVip]: isVipPage },
                    )}
                  >
                    Sign In
                  </span>
                </button>
                <ButtonBlue
                  className={cn(styles.signUpButton, { [styles.signUpButton_vip]: isVipPage })}
                  onClick={() => {
                    router.push('/user/sign-up')
                    handleNavClose()
                  }}
                >
                  Sign Up
                </ButtonBlue>
              </div>
            )}

            {authStatus === LoginStatuses.LOGIN && (
              <>
                <div
                  className={cn(
                    styles.mobileOnly,
                    styles.accountBlock,
                    { [styles.accountBlock_wallet]: isWalletPage },
                    { [styles.accountBlock_vip]: isVipPage },
                  )}
                >
                  <button type="button" className={cn(styles.signInButton, styles.mobileOnly)} onClick={goToAccount}>
                    <img src={IconWalletInverted} alt="My Wallet" width={22} height={22} />
                    <span>My Wallet</span>
                  </button>

                  <div className={styles.additionalAccount}>
                    <button type="button" className={cn(styles.mobileLogout)} onClick={handleLogout}>
                      <img src={IconLogout} alt="Log out" width={22} height={22} />
                      <span>Log Out</span>
                    </button>

                    <Link href="/account/settings" passHref>
                      <a onClick={handleNavClose}>
                        <img src={IconSettings} alt="Settings" width={22} height={22} />
                        <span>Settings</span>
                      </a>
                    </Link>
                  </div>
                </div>
                <ButtonBlue
                  className={cn(styles.headerMyWalletBtn, { [styles.headerMyWalletBtn_vip]: isVipPage })}
                  onClick={goToAccount}
                >
                  My Wallet
                </ButtonBlue>
                {/* <AccountDropdown isWallet={isWalletPage} className={cn(styles.dropdown, styles.desktopOnly)} /> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
