export function reduceWord(word: string, lettersCount: number): string {
  if (word.length - lettersCount < 3) {
    return word
  }
  if (word.length > lettersCount) {
    return word.slice(0, lettersCount) + '...'
  }

  return word
}
