import { Periods } from 'pages/account/wallets/info/wallet.types'

export const HALF_DAY = '12H' as const
export const DAY = '1D' as const
export const THREE_DAYS = '3D'
export const WEEK = '1W' as const
export const MONTH = '1M' as const
const HALF_DAY_IN_MILLISECONDS = 12 * 60 * 60 * 1000
const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000
const THREE_DAYS_IN_MILLISECONDS = 3 * DAY_IN_MILLISECONDS
const WEEK_IN_MILLISECONDS = 7 * DAY_IN_MILLISECONDS
const MONTH_IN_MILLISECONDS = 30 * DAY_IN_MILLISECONDS
export const PERIODS_IN_MS = {
  [HALF_DAY]: HALF_DAY_IN_MILLISECONDS,
  [DAY]: DAY_IN_MILLISECONDS,
  [THREE_DAYS]: THREE_DAYS_IN_MILLISECONDS,
  [WEEK]: WEEK_IN_MILLISECONDS,
  [MONTH]: MONTH_IN_MILLISECONDS,
}

export const INTERVALS = {
  [MONTH]: 60 * 60 * 1000, // 1 hour
  [WEEK]: 15 * 60 * 1000, // 15 min
  [THREE_DAYS]: 5 * 60 * 1000, // 5 min
  [DAY]: 5 * 60 * 1000, // 5 min
  [HALF_DAY]: 5 * 60 * 1000, // 5 min
}
export const DEFAULT_PERIOD = WEEK
export const CHART_PERIODS: Periods[] = [HALF_DAY, DAY, THREE_DAYS, WEEK, MONTH]
