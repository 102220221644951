import { useCallback, useEffect, useRef, useState } from 'react'

const useCopiedValue = (time?: number): [boolean, (value: string) => () => Promise<void>] => {
  const [isCopiedValue, setIsCopiedValue] = useState(false)
  const interval = useRef(null)

  const handleCopy = useCallback(
    (value: string) => async () => {
      if (interval.current) clearInterval(interval.current)

      setIsCopiedValue(true)
      await navigator.clipboard.writeText(value)
      interval.current = setTimeout(() => setIsCopiedValue(false), time || 2000)
    },
    [],
  )

  useEffect(() => () => clearInterval(interval.current), [])

  return [isCopiedValue, handleCopy]
}

export default useCopiedValue
