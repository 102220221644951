import cn from 'classnames'
import { useCallback } from 'react'
import Image from 'next/image'

import IconLoader from 'images/icon-loader-white.svg'

import walletIcon from './wallet.svg'
import styles from './styles.module.scss'

export default function ButtonBlue({
  className = '',
  disabled = false,
  isLoading = false,
  onClick = null,
  children,
  disableHover = true,
  isAccount = false,
  icon = false,
  disableHoverColor = false,
  bordered = false,
  ref = null,
  ...props
}) {
  const handleClick = useCallback(() => {
    if (isLoading) return
    if (disabled) return
    onClick?.()
  }, [onClick, isLoading, disabled])

  return (
    <button
      className={cn(
        isAccount ? styles.accountBtn : styles.button,
        {
          [styles.hover]: !disableHover && !isLoading,
          [styles.disabled]: disabled,
          [styles.isLoading]: isLoading,
          [styles.icon]: icon,
          [styles.withoutHoverColor]: disableHoverColor,
          [styles.bordered]: bordered,
        },
        className,
      )}
      type="button"
      onClick={handleClick}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      <div className={styles.children}>
        {isAccount && <Image src={walletIcon} alt="Wallet" width={56} height={56} />}
        {children}
      </div>

      {isLoading && <img src={IconLoader} alt="Loading" width={25} height={25} />}
    </button>
  )
}
