export const hideChatButton = () => {
  let button = document.getElementById('zd-button')
  if (!button) {
    const iframe = document.getElementById('launcher') as HTMLIFrameElement

    if (iframe) {
      iframe.style.width = '0'
      iframe.style.height = '0'
      iframe.style.overflow = 'hidden'
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      button = iframeDoc.querySelector('[data-garden-id="buttons.icon_button"]')
    }
  }
}

export const unhideChatButton = () => {
  let button = document.getElementById('zd-button')
  if (!button) {
    const iframe = document.getElementById('launcher') as HTMLIFrameElement

    if (iframe) {
      iframe.style.width = '64px'
      iframe.style.height = '64px'
      iframe.style.overflow = 'hidden'
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      button = iframeDoc.querySelector('[data-garden-id="buttons.icon_button"]')
    }
  }
}
