import cn from 'classnames'

import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import Skeleton from '../../skeleton'

import { formatNumberWithSpaces } from '../../../../utils/format-number-with-spaces'

import { LoansSummary } from '../../../../store/fetchers/loans/enum'

import { WalletType } from '../../../../store/fetchers/wallets/types'

import { useAppSelector } from '../../../../store/hooks'

import { getCurrencies } from '../../../../store/reducers/currencies'

import { ICurrency } from '../../../../store/fetchers/currencies'

import { floorNumber } from '../../../../utils/floor-number'

import styles from './styles.module.scss'
import { NavbarV2RightProps } from './navbar-v2-right.props'

import RabbitImg from './images/rabbit.svg'
import NavbarRightCurrency from './navbar-right-currency'

interface IBalanceView {
  code: string
  percent: number
  amount: number
  classNameColor: string
  key: number
  network?: string
}

interface IUnionData {
  code: string
  network: string
  amount: number
  pendingAmount?: number
}

function NavbarV2Right({
  className,
  wallets,
  savings,
  isWalletsLoading,
  loans,
  isLoansLoading,
  isSavingsLoading,
}: NavbarV2RightProps) {
  const router = useRouter()

  const [totalBalance, setTotalBalance] = useState(0)
  const [totalWalletPendingBalance, setTotalWalletPendingBalance] = useState<number>(0)
  const [isTotalBalanceLoading, setIsTotalBalanceLoading] = useState(true)
  const [balancesView, setBalancesView] = useState<IBalanceView[]>([])

  const [totalLoans, setTotalLoans] = useState(0)
  const [isTotalLoansLoading, setIsTotalLoansLoading] = useState(true)
  const [loansView, setLoansView] = useState<IBalanceView[]>([])

  const [totalBalanceSavings, setTotalBalanceSavings] = useState(0)
  const [savingsView, setSavingsView] = useState<IBalanceView[]>([])

  const [gridColumnsBalance, setGridColumnsBalance] = useState('')
  const [gridAreaBalance, setGridAreaBalance] = useState('')

  const [gridColumnsLoans, setGridColumnsLoans] = useState('')
  const [gridAreaLoans, setGridAreaLoans] = useState('')

  const [gridColumnsSavings, setGridColumnsSavings] = useState('')
  const [gridAreaSaving, setGridAreaSaving] = useState('')

  const [sortedLoans, setSortedLoans] = useState<LoansSummary>(null)
  const [sortedWallets, setSortedWallets] = useState<WalletType[]>([])
  const [walletCurrencies, setWalletCurrencies] = useState<ICurrency[]>([])
  const [loansCurrencies, setLoansCurrencies] = useState<ICurrency[]>([])

  const currencies = useAppSelector(getCurrencies)

  useEffect(() => {
    if ((wallets.length !== 0 || !isWalletsLoading) && !isSavingsLoading) {
      calculateTotalBalance()
    }
  }, [wallets, isWalletsLoading, isSavingsLoading])

  useEffect(() => {
    if (!isLoansLoading) {
      setIsTotalLoansLoading(false)
      setTotalLoans(+loans.depositAmountReducedInUsd)
    }
  }, [loans, isLoansLoading])

  useEffect(() => {
    if (currencies.length) {
      const currentCurrencies = [...currencies]

      setWalletCurrencies(
        currentCurrencies
          .filter((item) => item.is_allowed_for_wallet)
          .sort((a, b) => new Date(b.listed_at).getTime() - new Date(a.listed_at).getTime())
          .slice(0, 3),
      )
      setLoansCurrencies(
        currentCurrencies
          .filter((item) => item.is_loan_deposit_enabled || item.is_loan_receive_enabled)
          .sort((a, b) => new Date(b.listed_at).getTime() - new Date(a.listed_at).getTime())
          .slice(0, 3),
      )
    }
  }, [currencies])

  useEffect(() => {
    if (sortedLoans && sortedLoans?.groups) {
      let loansView: IBalanceView[] = []
      for (let i = 0; i < sortedLoans?.groups?.length; i++) {
        if (!sortedLoans?.groups[i]?.amount) {
          continue
        }
        const view: IBalanceView = {
          code: sortedLoans?.groups[i]?.code,
          network: sortedLoans?.groups[i]?.network,
          amount: floorNumber(+sortedLoans?.groups[i]?.amount, 2),
          percent: +((+sortedLoans?.groups[i]?.amount / totalLoans) * 100).toFixed(1),
          classNameColor: '',
          key: i + 1,
        }
        loansView.push(view)

        if (i >= 2) {
          break
        }
      }
      loansView = addClassNameToBalancesView(loansView)

      if (sortedLoans?.groups?.length > 3 && loansView?.length === 3) {
        loansView.push(calculateOtherBalance(loansView, totalLoans))
      }
      setLoansView(loansView)
    }
  }, [sortedLoans])

  useEffect(() => {
    if (sortedWallets.length || (savings && savings?.groups && savings?.groups?.length)) {
      const unionData: Map<string, IUnionData> = new Map<string, IUnionData>()
      const unionDataSavings: Map<string, IUnionData> = new Map<string, IUnionData>()

      const finalData: IUnionData[] = []
      const finalDataSavings: IUnionData[] = []

      sortedWallets.map((item) => {
        const unionItem: IUnionData = { amount: +item.balance, code: item.code, network: item.network }
        unionData.set(`${item.code}-${item.network}`, unionItem)
        return item
      })

      savings?.groups?.map((item) => {
        const unionItem: IUnionData = {
          amount: +item.amount,
          code: item.code,
          network: item.network,
          pendingAmount: item.pendingAmount ? +item.pendingAmount : null,
        }
        unionDataSavings.set(`${item.code}-${item.network}`, unionItem)
        return item
      })

      let balancesView: IBalanceView[] = []
      let balancesSavingsView: IBalanceView[] = []

      finalData.push(...unionData.values())
      finalData.filter((item) => item.amount < 0.01).sort((a, b) => b.amount - a.amount)

      finalDataSavings.push(...unionDataSavings.values())
      finalDataSavings.filter((item) => item.amount < 0.01).sort((a, b) => b.amount - a.amount)

      for (let i = 0; i < finalData.length; i++) {
        const view: IBalanceView = {
          code: finalData[i].code,
          network: finalData[i].network,
          amount: floorNumber(+finalData[i].amount, 2),
          percent: +((finalData[i].amount / totalBalance) * 100).toFixed(1),
          classNameColor: '',
          key: i + 1,
        }
        balancesView.push(view)

        if (i >= 2) {
          break
        }
      }

      for (let i = 0; i < finalDataSavings.length; i++) {
        const view: IBalanceView = {
          code: finalDataSavings[i].code,
          network: finalDataSavings[i].network,
          amount: floorNumber(+finalDataSavings[i].amount, 2),
          percent: +((floorNumber(finalDataSavings[i].amount, 2) / floorNumber(totalBalanceSavings, 2)) * 100).toFixed(
            1,
          ),
          classNameColor: '',
          key: i + 1,
        }
        balancesSavingsView.push(view)

        if (i >= 2) {
          break
        }
      }
      balancesView.sort((a, b) => b.amount - a.amount)
      balancesView = addClassNameToBalancesView(balancesView)

      balancesSavingsView.sort((a, b) => b.amount - a.amount)
      balancesSavingsView = addClassNameToBalancesView(balancesSavingsView)

      if (finalData?.length > 3) {
        balancesView.push(calculateOtherBalance(balancesView, totalBalance))
      }

      if (finalDataSavings?.length > 3) {
        balancesSavingsView.push(calculateOtherBalance(balancesSavingsView, totalBalanceSavings))
      }

      setBalancesView(balancesView)
      setSavingsView(balancesSavingsView)
    }
  }, [sortedWallets, savings, totalBalance, totalBalanceSavings])

  useEffect(() => {
    if (totalBalance) {
      constructBalanceView()
    }
  }, [totalBalance])

  useEffect(() => {
    if (totalLoans) {
      constructLoansView()
    }
  }, [totalLoans])

  useEffect(() => {
    if (balancesView.length !== 0) {
      constructGridColumnsInBalance()
      constructGridAreaInBalance()
    }
  }, [balancesView])

  useEffect(() => {
    if (loansView.length !== 0) {
      constructGridColumnsInLoans()
      constructGridAreaInLoans()
    }
  }, [loansView])

  useEffect(() => {
    if (savingsView.length !== 0) {
      constructGridColumnsInSavings()
      constructGridAreaInSavings()
    }
  }, [savingsView])

  const calculateTotalBalance = (): void => {
    let totalBalance = 0
    let totalPendingBalance = 0
    wallets.forEach((wallet) => {
      totalBalance += wallet.balance * +wallet.rate
      totalPendingBalance += wallet.pendingBalance * +wallet.rate
    })
    setTotalBalanceSavings(floorNumber(+savings?.depositAmountReducedInUsd, 2))
    setTotalBalance(+totalBalance.toFixed(2))
    setTotalWalletPendingBalance(+totalPendingBalance.toFixed(2))
    setIsTotalBalanceLoading(false)
  }

  const constructBalanceView = (): void => {
    setBalancesView([])
    const currentWallets = JSON.parse(JSON.stringify(wallets))

    for (let i = 0; i < currentWallets.length; i++) {
      currentWallets[i].balance = currentWallets[i].balance * +currentWallets[i].rate
    }
    setSortedWallets([...currentWallets].filter((item) => item.balance > 0.01).sort((a, b) => b.balance - a.balance))
  }

  const constructLoansView = (): void => {
    const currentLoans = { ...loans }
    if (currentLoans) {
      currentLoans.groups = [...currentLoans.groups]?.sort((a, b) => +b.amount - +a.amount)
      setSortedLoans(currentLoans)
    }
  }

  const calculateOtherBalance = (balancesView: IBalanceView[], total: number): IBalanceView => {
    const otherBalanceView: IBalanceView = {
      code: 'other',
      classNameColor: styles.detail__content__line_gray,
      key: 4,
      percent: 0,
      amount: 0,
    }
    let otherAmount = total
    let otherPercent = 100

    for (const balanceView of balancesView) {
      otherAmount -= balanceView.amount
      otherPercent -= balanceView.percent
    }

    otherBalanceView.amount = +otherAmount.toFixed(2)
    otherBalanceView.percent = +otherPercent.toFixed(1)
    return otherBalanceView
  }

  const addClassNameToBalancesView = (balancesView: IBalanceView[]): IBalanceView[] => {
    if (balancesView.length === 0) {
      return []
    }
    balancesView[0].classNameColor = styles.detail__content__line_violet

    if (balancesView[1]) {
      balancesView[1].classNameColor = styles.detail__content__line_yellow
    }

    if (balancesView[2]) {
      balancesView[2].classNameColor = styles.detail__content__line_green
    }

    return balancesView
  }

  const constructGridColumnsInBalance = (): void => {
    let gridColumnsStyle = ''
    for (const balanceView of balancesView) {
      gridColumnsStyle += `calc(${Math.round(balanceView.percent)}% - 4px) `
    }
    gridColumnsStyle += ''
    setGridColumnsBalance(gridColumnsStyle)
  }

  const constructGridColumnsInLoans = (): void => {
    let gridColumnsStyle = ''
    for (const loanView of loansView) {
      gridColumnsStyle += `calc(${Math.round(loanView.percent)}% - 4px) `
    }
    gridColumnsStyle += ''
    setGridColumnsLoans(gridColumnsStyle)
  }

  const constructGridColumnsInSavings = (): void => {
    let gridColumnsStyle = ''
    for (const savingView of savingsView) {
      gridColumnsStyle += `calc(${Math.round(savingView.percent)}% - 4px) `
    }
    gridColumnsStyle += ''
    setGridColumnsSavings(gridColumnsStyle)
  }

  const constructGridAreaInBalance = (): void => {
    let gridAreas = ''

    if (balancesView.length === 1) {
      gridAreas = 'violet'
    } else if (balancesView.length === 2 && balancesView[1].code !== 'other') {
      gridAreas = 'violet yellow'
    } else if (balancesView.length === 2 && balancesView[1].code === 'other') {
      gridAreas = 'violet gray'
    } else if (balancesView.length === 3 && balancesView[2].code !== 'other') {
      gridAreas = 'violet yellow green'
    } else if (balancesView.length === 3 && balancesView[2].code === 'other') {
      gridAreas = 'violet yellow gray'
    } else {
      gridAreas = 'violet yellow green gray'
    }
    setGridAreaBalance(gridAreas)
  }

  const constructGridAreaInLoans = (): void => {
    let gridAreas = ''
    if (loansView.length === 1) {
      gridAreas = 'violet'
    } else if (loansView.length === 2) {
      gridAreas = 'violet yellow'
    } else if (loansView.length === 3) {
      gridAreas = 'violet yellow green'
    } else if (loansView.length === 4) {
      gridAreas = 'violet yellow green gray'
    }
    setGridAreaLoans(gridAreas)
  }

  const constructGridAreaInSavings = (): void => {
    let gridAreas = ''

    if (savingsView.length === 1) {
      gridAreas = 'violet'
    } else if (savingsView.length === 2 && savingsView[1].code !== 'other') {
      gridAreas = 'violet yellow'
    } else if (savingsView.length === 2 && savingsView[1].code === 'other') {
      gridAreas = 'violet gray'
    } else if (savingsView.length === 3 && savingsView[2].code !== 'other') {
      gridAreas = 'violet yellow green'
    } else if (savingsView.length === 3 && savingsView[2].code === 'other') {
      gridAreas = 'violet yellow gray'
    } else {
      gridAreas = 'violet yellow green gray'
    }
    setGridAreaSaving(gridAreas)
  }

  return router.pathname !== '/account/dashboard' ? (
    <div className={cn(styles.sections, className)}>
      {+totalLoans || +totalBalance || +totalBalanceSavings ? (
        <>
          <div className={cn(styles.section)}>
            {(isTotalBalanceLoading || totalBalance !== 0) && (
              <p className={styles.section__name}>
                Total <span>wallet</span> balance
              </p>
            )}
            {isTotalBalanceLoading ? (
              <Skeleton />
            ) : totalBalance ? (
              <>
                <h3 className={styles.section__amount}>${formatNumberWithSpaces(totalBalance)}</h3>
                {+floorNumber(totalWalletPendingBalance, 2) !== 0 && (
                  <h5 className={styles.section__pending}>
                    <span>${formatNumberWithSpaces(+floorNumber(totalWalletPendingBalance, 2))}</span> in pending
                  </h5>
                )}
                <div
                  className={styles.section__lines}
                  style={{ gridTemplateColumns: gridColumnsBalance, gridTemplateAreas: `"${gridAreaBalance}"` }}
                >
                  <div className={cn(styles.line, styles.line_violet)}></div>
                  {balancesView.length > 1 && <div className={cn(styles.line, styles.line_yellow)}></div>}
                  {balancesView.length > 2 && <div className={cn(styles.line, styles.line_green)}></div>}
                  {balancesView.length > 3 && <div className={cn(styles.line, styles.line_gray)}></div>}
                </div>
                <div className={styles.section__details}>
                  {balancesView.map((balanceView) => (
                    <div key={balanceView.key} className={styles.detail}>
                      <div className={styles.detail__content}>
                        <div className={cn(styles.detail__content__line, balanceView.classNameColor)}></div>
                        <div className={styles.detail__content__coin}>
                          {balanceView.code === 'other' ? 'Other' : balanceView.code.toUpperCase()}{' '}
                          {balanceView.network ? `(${balanceView.network.toUpperCase()})` : ''} - {balanceView.percent}%
                        </div>
                      </div>
                      <div className={styles.detail__amount}>${formatNumberWithSpaces(balanceView.amount)}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className={cn(styles.section)}>
            {(isTotalBalanceLoading || totalBalanceSavings !== 0) && (
              <p className={styles.section__name}>
                Total <span>saving</span> balance
              </p>
            )}
            {isTotalBalanceLoading ? (
              <Skeleton />
            ) : totalBalanceSavings ? (
              <>
                <h3 className={styles.section__amount}>${formatNumberWithSpaces(totalBalanceSavings)}</h3>
                {+floorNumber(+savings?.pendingAmountReducedInUsd, 2) !== 0 && (
                  <h5 className={styles.section__pending}>
                    <span>${formatNumberWithSpaces(+floorNumber(+savings?.pendingAmountReducedInUsd, 2))}</span> in
                    pending
                  </h5>
                )}
                <div
                  className={styles.section__lines}
                  style={{ gridTemplateColumns: gridColumnsSavings, gridTemplateAreas: `"${gridAreaSaving}"` }}
                >
                  <div className={cn(styles.line, styles.line_violet)}></div>
                  {savingsView.length > 1 && <div className={cn(styles.line, styles.line_yellow)}></div>}
                  {savingsView.length > 2 && <div className={cn(styles.line, styles.line_green)}></div>}
                  {savingsView.length > 3 && <div className={cn(styles.line, styles.line_gray)}></div>}
                </div>
                <div className={styles.section__details}>
                  {savingsView.map((savingView) => (
                    <div key={savingView.key} className={styles.detail}>
                      <div className={styles.detail__content}>
                        <div className={cn(styles.detail__content__line, savingView.classNameColor)}></div>
                        <div className={styles.detail__content__coin}>
                          {savingView.code === 'other' ? 'Other' : savingView.code.toUpperCase()}{' '}
                          {savingView.network ? `(${savingView.network.toUpperCase()})` : ''} - {savingView.percent}%
                        </div>
                      </div>
                      <div className={styles.detail__amount}>${formatNumberWithSpaces(savingView.amount)}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className={cn(styles.section)}>
            {(isTotalLoansLoading || totalLoans !== 0) && (
              <p className={styles.section__name}>
                Total <span>collateral</span> balance
              </p>
            )}
            {isTotalLoansLoading ? (
              <Skeleton />
            ) : +totalLoans ? (
              <>
                <h3 className={styles.section__amount}>${formatNumberWithSpaces(+(+totalLoans).toFixed(2))}</h3>
                {+floorNumber(+loans?.pendingDepositAmountReducedInUsd, 2) !== 0 && (
                  <h5 className={styles.section__pending}>
                    <span>${formatNumberWithSpaces(+floorNumber(+loans?.pendingDepositAmountReducedInUsd, 2))}</span> in
                    pending
                  </h5>
                )}
                <div
                  className={styles.section__lines}
                  style={{ gridTemplateColumns: gridColumnsLoans, gridTemplateAreas: `"${gridAreaLoans}"` }}
                >
                  <div className={cn(styles.line, styles.line_violet)}></div>
                  <div className={cn(styles.line, styles.line_yellow)}></div>
                  <div className={cn(styles.line, styles.line_green)}></div>
                  <div className={cn(styles.line, styles.line_gray)}></div>
                </div>
                <div className={styles.section__details}>
                  {loansView.map((loanView) => (
                    <div key={loanView.key} className={styles.detail}>
                      <div className={styles.detail__content}>
                        <div className={cn(styles.detail__content__line, loanView.classNameColor)}></div>
                        <div className={styles.detail__content__coin}>
                          {loanView.code === 'other' ? 'Other' : loanView.code.toUpperCase()}{' '}
                          {loanView.network ? `(${loanView.network.toUpperCase()})` : ''} - {loanView.percent}%
                        </div>
                      </div>
                      <div className={styles.detail__amount}>${formatNumberWithSpaces(loanView.amount)}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        !isTotalLoansLoading &&
        !isTotalBalanceLoading && (
          <div className={styles.no_balance}>
            <img src={RabbitImg} alt="rabbit image" />
            <p>Your balances will appear here</p>
          </div>
        )
      )}
    </div>
  ) : (
    <div className={cn(styles.sections, className)}>
      <div className={styles.updates}>
        <h3 className={styles.updates__title}>Wallet updates</h3>
        <p className={styles.updates__text}>
          New assets that are now supported in CoinRabbit Wallet for storage and exchange
        </p>
        {walletCurrencies.length > 0 &&
          walletCurrencies.map((item, index) => <NavbarRightCurrency key={index} currency={item} />)}
      </div>

      <div className={styles.updates}>
        <h3 className={styles.updates__title}>Loan updates</h3>
        <p className={styles.updates__text}>New assets that are now available as collateral options</p>

        {loansCurrencies.map((item, index) => (
          <NavbarRightCurrency key={index} currency={item} />
        ))}
      </div>
    </div>
  )
}

export default NavbarV2Right
