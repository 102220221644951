import loansSlice, { storeKey as loansStoreKey } from './loans'
import ratesSlice, { storeKey as ratesStoreKey } from './rates'
import currenciesSlice, { storeKey as currenciesStoreKey } from './currencies'
import earningsSlice, { storeKey as earningsStoreKey } from './earnings'
import usersSlice, { storeKey as usersStoreKey } from './users'
import settingsSlice, { storeKey as settingsStoreKey } from './settings'
import walletsSlice, { storeKey as walletStoreKey } from './wallets'

export const reducers = {
  [loansStoreKey]: loansSlice,
  [ratesStoreKey]: ratesSlice,
  [currenciesStoreKey]: currenciesSlice,
  [earningsStoreKey]: earningsSlice,
  [usersStoreKey]: usersSlice,
  [settingsStoreKey]: settingsSlice,
  [walletStoreKey]: walletsSlice,
}
