import type { IWalletOperationsResponse, WalletType } from 'store/fetchers/wallets/types'
import api, { apiExchanges, setApiUserToken } from 'config/api'
import { WhiteListItem } from 'store/reducers/settings'
import Error from 'next/error'

import { getStorageItem, STORAGE_KEYS } from '../../reducers/storage'

export const _fetchWallets = async (): Promise<WalletType[]> => {
  try {
    const { data } = await api.get('/v2/wallet/balance')
    if (data.response?.length && Array.isArray(data.response)) {
      return data.response
    }
    return []
  } catch (ignore) {
    return []
  }
}

export const fetchOperationStatuses = async (): Promise<IWalletOperationsResponse[]> => {
  try {
    const { data } = await api.get('/v2/wallet/operation-statuses')
    return data.response
  } catch (err) {
    throw new Error(err)
  }
}

export const _fetchWalletsAll = async (): Promise<WalletType[]> => {
  try {
    const { data } = await api.get('/v2/wallet/balance/all')
    if (data.response?.length && Array.isArray(data.response)) {
      return data.response
    }
    return []
  } catch (ignore) {
    return []
  }
}

export const _fetchWhitelistWallets = async (): Promise<WhiteListItem[]> => {
  try {
    const token = getStorageItem(STORAGE_KEYS.AUTH_TOKEN)
    await setApiUserToken(token)
    const { data } = await api.get('/v2/wallet/whitelist')
    if (data.response?.whitelists?.length) {
      return data.response?.whitelists
    }
    return []
  } catch (ignore) {
    return []
  }
}

export const _outputWallet = async (params: {
  from_code: string
  from_network: string
  amount: string
  address: string
  verification_token: string
  code_2fa: string
  tag?: string
}): Promise<{ message: string }> => {
  try {
    const { data } = await api.post('/v2/wallet/output', {
      params: params,
    })
    if (data.result) {
      return data.response
    } else {
      return { message: data.message }
    }
  } catch (error) {
    throw new Error(JSON.parse(JSON.stringify(error)))
  }
}

export const _fetchAvailableWallets = async (): Promise<WalletType[]> => {
  try {
    const { data } = await api.get('/v2/wallet/list')
    if (data.response?.length && Array.isArray(data.response)) {
      return data.response
    }
    return []
  } catch (ignore) {
    return []
  }
}

export const _setWalletsShowList = async ({ params }: { params: WalletType[] }): Promise<WalletType[]> => {
  try {
    const walletList = params.map((wallet) => {
      return { code: wallet.code, network: wallet.network }
    })
    await api.post('/v2/wallet/show', {
      params: { wallets: walletList },
    })
  } catch {}
  return await _fetchWallets()
}

export const _whitelistWallets = async (params: {
  code: string
  network: string
  tag?: string
  address: string
  verification_token: string | string[]
  code_2fa: string
  address_name?: string
}) => {
  try {
    const { data } = await api.put('/v2/wallet/whitelist', {
      params: params,
    })

    if (data.result) {
      return data.response
    }

    return { message: data.message, result: data.result, ...data?.response }
  } catch (e) {
    return { message: e.message, result: e.result, ...e?.response }
  }
}

export const _deleteWhitelistWallets = async (params: {
  code: string
  network: string
  address: string
  tag: string
  address_name: string
}) => {
  try {
    const { data } = await api.post('/v2/wallet/delete-whitelist', {
      params: params,
    })

    if (data.result) {
      return true
    }

    return false
  } catch {
    return false
  }
}

export const _inputWallet = async (params: {
  code: string
  network: string
  amount: string
}): Promise<{ address: string; extraId: string }> => {
  try {
    const { data } = await api.post('/v2/wallet/input', {
      params: params,
    })

    if (data.result && data.response) {
      return {
        address: data.response.address,
        extraId: data.response.extraId,
      }
    }

    return { address: '', extraId: '' }
  } catch {
    return { address: '', extraId: '' }
  }
}

export const _inputWalletEstimate = async (params: {
  code: string
  network: string
  amount: string
}): Promise<{ amountFrom: string; amountTo: string } | { message: string }> => {
  try {
    const { data } = await api.post('/v2/wallet/input/estimate', {
      params: params,
    })

    if (data.result && data.response) {
      return {
        amountFrom: data.response.amountFrom,
        amountTo: data.response.amountTo,
      }
    }

    return { amountFrom: '', amountTo: '' }
  } catch (e) {
    let message = 'Something went wrong'
    const error = new Error(e)

    if ('message' in e && !e.message.includes('code')) {
      message = e.message
    }
    if (error?.props?.response?.message) {
      message = error?.props?.response?.message
    }
    return { message }
  }
}

export const _outputWalletEstimate = async (params: {
  code: string
  network: string
  amount: string
}): Promise<{ message: string } | number> => {
  try {
    const { data } = await api.post('/v2/wallet/output/estimate', {
      params: params,
    })
    if (!data.result && data.message) {
      return {
        message: data.message,
      }
    }

    return data.response.fee
  } catch (e) {
    let message = 'Something went wrong'
    if ('message' in e && !e.message.includes('code')) {
      message = e.message
    }
    return { message }
  }
}

export const _estimateExchange = async (params: {
  fromCurrency: string
  fromNetwork?: string
  toCurrency: string
  toNetwork?: string
  fromAmount: string
}) => {
  try {
    const { data } = await apiExchanges.get('/v2/conversion/estimated-amount', {
      params: params,
    })
    if (data.result) {
      return data.response
    }

    return null
  } catch (e) {
    let message = 'Something went wrong'
    if ('message' in e && !e.message.includes('code')) {
      message = e.message
    }
    return { message }
  }
}

export const _getExchangeStatus = async (params: { id: string }) => {
  try {
    const { data } = await apiExchanges.get(`/v2/conversion/${params.id}/status`)
    if (data.result) {
      return data.response.status
    }

    return null
  } catch (e) {
    let message = 'Something went wrong'
    if ('message' in e && !e.message.includes('code')) {
      message = e.message
    }
    return { message }
  }
}

export const _createExchange = async (params: {
  fromAmount: string
  fromCurrency: string
  toCurrency: string
  rateId: string
}) => {
  try {
    const { data } = await apiExchanges.post('/v2/conversion', {
      params: params,
    })

    return data.response as {
      id: string
      id_conversion: string
      from: string
      to: string
      fromCurrency: string
      toCurrency: string
      fromAmount: number
      toAmount: number
      createdAt: Date
    }
  } catch (e) {
    return null
  }
}
