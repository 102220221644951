export const EVENT_TYPES = {
  WITHDRAW: 'WITHDRAW',
  CHARGING: 'CHARGING',
  DEPOSIT: 'DEPOSIT',
  INCREASE: 'INCREASE',
}

export const EVENT_STATUS = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  PROCESSING: 'PROCESSING',
}

export const SESSION_ITEMS = {
  trackCode: 'trackCode',
  referral: 'referral',
  userToken: 'userToken',
  grecaptcha: '_grecaptcha',
  captchaSession: 'bge_eng_mt',
}

export const PERCENTAGES = {
  loan: 14,
  earn: 5,
}

export const constants = {
  codeLength: 6,
  tfaCodeLength: 6,
}

export const DEFAULT_CRYPTOCOMPARE_EXCHANGE = 'CCCAGG'
export const DEFAULT_FIAT_CURRENCY = 'USD'
