import api, { TBodyResponse } from 'config/api'
import { EExchangeDirections, EUserLoanFlow, IExchangeData, IFormattedLoan } from 'store/fetchers/loans/enum'

import { Transactions } from 'pages/account/dashboard/transaction-tab/interfaces'

type ELoanReceiveTo = 'external_wallet' | 'internal_wallet'

/*
estimate loan amount in form
 */

export const estimateLoanAmount = async (params: {
  from_code: string
  from_network: string
  to_code: string
  to_network: string
  amount: string
  exchange: EExchangeDirections
  ltv_percent: string
}): Promise<{
  result: boolean
  response:
    | {
        message: string
      }
    | IExchangeData
}> => {
  try {
    const { data } = await api.get('/v2/loans/estimate', {
      params: params,
    })
    if (!data.result) {
      throw new Error(data.message)
    }
    return data
  } catch (error) {
    let errorMessage = null
    switch (error.response?.code) {
      case 'INVALID_PAIR':
        errorMessage = 'This loan pair is not supported at the moment.'
        break
    }
    return {
      result: false,
      response: {
        message: errorMessage,
      },
    }
  }
}

/*
estimate repayment amount
 */

export const estimateRepaymentAmount = async (
  id: string,
  params: {
    receive_from: string
    receive_by_network?: string
    receive_by_code?: string
    amount?: string
  },
): Promise<{
  result: boolean
  response: {
    amount: string
  }
}> => {
  try {
    const { data } = await api.get(`/v2/loans/${id}/pledge/estimate`, {
      params: params,
    })
    if (!data.result) {
      throw new Error(data.message)
    }
    return data
  } catch (error) {
    throw new Error(error.response.message)
  }
}

/*
create loan
 */

export const createLoan = async (params: {
  user_id?: string
  loan: {
    currency_code: string
    currency_network: string
  }
  deposit: {
    currency_code: string
    currency_network: string
    expected_amount: string
  }
  ltv_percent: string
}): Promise<IFormattedLoan> => {
  try {
    const { data } = await api.post('/v2/loans', {
      params: params,
    })
    return data.response
  } catch (ignore) {
    return null
  }
}

/*
confirm create loan
 */

export const confirmCreateLoan = async ({
  loan_id,
  ...params
}: {
  user_id?: string
  loan_id: string
  loan: {
    receive_address?: string
    receive_extra_id?: string
    receive_to?: ELoanReceiveTo
    receive_from?: ELoanReceiveTo
  }
  agreed_to_tos?: boolean
}): Promise<
  | TBodyResponse<{
      incorrect_price: boolean
      not_enough_liquidity: boolean
      liquidity_delta?: number
      address?: string
      extraId?: string
    }>
  | false
> => {
  try {
    const { data } = await api.post(`/v2/loans/${loan_id}/confirm`, {
      params: params,
    })
    return data
  } catch (ignore) {
    return false
  }
}

/*
fetch single loan by id
 */

export const fetchUserLoans = async (): Promise<IFormattedLoan[]> => {
  try {
    const { data } = await api.get('/v2/loans')
    return data?.response || []
  } catch (e) {
    return []
  }
}

/*
fetch single loan by id
 */

export const _fetchLoanById = async ({ loan_id }: { loan_id: string }): Promise<IFormattedLoan> => {
  try {
    const { data } = await api.get(`/v2/loans/${loan_id}`)
    return data?.response
  } catch (ignore) {
    return null
  }
}

/*
verification create new loan by code from twilio
 */

export interface IIncreaseDepositAmountResult {
  result: boolean
  response: {
    address: string
    extraId: string
    isFallbackTx: boolean
    real_increase_amount: number
  }
  message?: string
  code?: string
}

export const increaseDepositAmount = async ({
  amount,
  loan_id,
  receive_from,
}: {
  loan_id: string
  amount: string
  receive_from?: string
}): Promise<IIncreaseDepositAmountResult> => {
  try {
    const { data } = await api.post(`/v2/loans/${loan_id}/increase`, {
      params: {
        deposit: {
          amount,
          receive_from,
        },
      },
    })

    return {
      result: data.result,
      response: data.response,
      message: data.message,
    }
  } catch (err) {
    return {
      result: false,
      message: err.message,
      response: null,
    }
  }
}

/*
verification create new loan by code from twilio
 */

export const createPledgeRedemption = async ({
  loan_id,
  ...params
}: {
  loan_id: string
  address: string
  extra_id: string
  verification_token?: string
  code_2fa: string
  receive_from?: EUserLoanFlow
  amount?: string
  repay_by_code?: string
  repay_by_network?: string
}): Promise<boolean> => {
  try {
    const { data } = await api.post(`/v2/loans/${loan_id}/pledge`, {
      params: params,
    })

    return !!data.result
  } catch (ignore) {
    return false
  }
}

export const updateInitialDeposit = async ({ loan_id }: { loan_id: string }): Promise<boolean> => {
  try {
    const { data } = await api.post(`/v2/loans/${loan_id}/deposit`)

    return data.result
  } catch (ignore) {
    return false
  }
}

/*
estimate increase amount for loan by id
 */

export const estimateIncreaseAmount = async ({
  loan_id,
  amount,
  receive_from,
}: {
  loan_id: string
  amount: string
  receive_from?: EUserLoanFlow
}): Promise<
  | {
      is_error: undefined
      deposit: {
        amount: number
        new_amount: string
      }
      max: string
      liquidation_price: number
      precision: number
      real_increase_amount: number
    }
  | { is_error: true; error: Error }
> => {
  try {
    const { data } = await api.get(`/v2/loans/${loan_id}/increase/estimate`, {
      params: {
        amount: amount,
        receive_from: receive_from,
      },
    })

    return data.response
  } catch (ignore) {
    return null
  }
}

export const updateIncreaseTxHash = async ({
  loan_id,
  ...body
}: {
  loan_id: string
  hash: string
}): Promise<{
  result: boolean
  error?: string
}> => {
  try {
    const { data } = await api.put(`/v2/loans/${loan_id}/increase/fallback-tx`, {
      params: body,
    })

    return {
      result: data.result,
    }
  } catch (error) {
    const errorMessage = error.response?.data?.response?.message || 'Request error'

    return {
      result: false,
      error: errorMessage,
    }
  }
}

export const getTotalAmountOfLoans = async () => {
  try {
    const { data } = await api.get(`/v2/loans/summary`)
    return data.response
  } catch (e) {
    return false
  }
}

export const getTotalAmountOfBalance = async () => {
  try {
    const { data } = await api.get(`/v2/wallet/balance/summary`)

    return data.response
  } catch (e) {
    return false
  }
}

export const getAllTransactions = async (): Promise<Transactions[]> => {
  try {
    const { data } = await api.get(`/v2/loans/transactions`)
    return data.response
  } catch (ignore) {
    return null
  }
}

export const getAmountToIncrease = async (loanId: string): Promise<string> => {
  try {
    const { data } = await api.get(`/v2/loans/${loanId}/get-amount-to-increase`)
    return data.response
  } catch (ignore) {
    return null
  }
}

export const autoIncrease = async (params: {
  auto_increase: boolean
  code_2fa: string
  verification_token: string
  loan_id: string
}): Promise<string> => {
  try {
    const { data } = await api.post(`/v2/loans/${params.loan_id}/increase/auto`, { params })
    return data.message
  } catch (e) {
    return null
  }
}
