import { useEffect, useState } from 'react'
import { callbackQueue } from 'utils/callback-queue'

export default function useViewport() {
  const [viewport, setViewport] = useState('')

  const handleResize = callbackQueue(() => {
    let newViewport
    if (window.innerWidth >= 1206) {
      newViewport = 'desktop'
    } else if (window.innerWidth >= 1021 && window.innerWidth <= 1205) {
      newViewport = 'tablet'
    } else if (window.innerWidth >= 766 && window.innerWidth <= 1020) {
      newViewport = 'xs-tablet'
    } else if (window.innerWidth >= 481 && window.innerWidth <= 765) {
      newViewport = 'mobile'
    } else if (window.innerWidth <= 480) {
      newViewport = 'xs-mobile'
    }
    if (newViewport !== viewport) setViewport(newViewport)
  }, 16.6)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return [viewport]
}
